import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ChangePasswordScreen from "./ChangePasswordScreen";
import MyProfileEditScreen from "./MyProfileEditScreen";
import {
  useGetMyDetailsQuery,
  useUpdateMyDetailsMutation,
} from "../slices/associatesSlice";
import { setCredentials } from "../slices/authSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  useGetMyGymQuery,
  useUpdateUsersTokenMutation,
} from "../slices/gymSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { toast } from "react-toastify";
import preferredLanguage from "../assets/preferredLanguage.json";

const MyProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const pageDomain = window.location.origin; // to get domain
  const [editable, setEditable] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [colorShow, setColorShow] = useState(false);
  const [themeColor, setThemeColor] = useState(
    userInfo.themeColor ? userInfo.themeColor : "cyan"
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    userInfo.preferredLanguage ? userInfo.preferredLanguage : "English"
  );
  // const [changeUrl, setChangeUrl] = useState(false);

  const dispatch = useDispatch();

  const [updateGym] = useUpdateUsersTokenMutation();
  const [updateMyDetails, { isUpdateLoading }] = useUpdateMyDetailsMutation();

  let {
    data: myDetails,
    isLoading,
    error,
    refetch,
  } = useGetMyDetailsQuery({
    token: userInfo.token,
  });

  let {
    data: myGym,
    isLoading: isGymLoading,
    error: errorInMyGym,
    refetch: refetchGym,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  useEffect(() => {
    refetchGym();
  }, [refetchGym]);

  useEffect(() => {
    refetch();
    if (myDetails)
      dispatch(
        setCredentials({
          ...myDetails,
          token: userInfo.token,
          audioOn: userInfo.audioOn,
          visitorsStartDate: userInfo.visitorsStartDate,
          visitorsEndDate: userInfo.visitorsEndDate,
          attendancesStartDate: userInfo.attendancesStartDate,
          attendancesEndDate: userInfo.attendancesEndDate,
          paymentsStartDate: userInfo.paymentsStartDate,
          paymentsEndDate: userInfo.paymentsEndDate,
          dashboardStartDate: userInfo.dashboardStartDate,
          dashboardEndDate: userInfo.dashboardEndDate,
          themeColor: themeColor,
          preferredLanguage: selectedLanguage,
        })
      );
  }, [myDetails, refetch, dispatch, editable, isUpdateLoading]);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleChangePassword = () => {
    setChangePassword(false);
  };

  const submitEdit = async () => {
    try {
      await updateGym({
        gymId: userInfo.gymId._id,
        token: userInfo.token,
      }).unwrap();

      toast.success("URL is updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleSubmit = async (user) => {
    setModalShow(true);
  };

  const copyUrlClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("URL is copied");
      })
      .catch((err) => {
        toast.error("copy failed");
        // Handle errors, such as permissions denied
      });
  };

  const handleChangeColor = () => {
    setColorShow(true);
  };

  const submitColor = () => {
    setColorShow(false);
  };

  const updateLanguage = (lang) => {
    setSelectedLanguage(lang);
    dispatch(
      setCredentials({
        ...userInfo,
        preferredLanguage: lang,
      })
    );
  };

  const handleSubmitColor = async () => {
    try {
      await updateMyDetails({
        themeColor: themeColor,
        token: userInfo.token,
      }).unwrap();
      toast.success("Theme Color updated successfuly ");
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleSubmitLanguage = async () => {
    try {
      await updateMyDetails({
        preferredLanguage: selectedLanguage,
        token: userInfo.token,
      }).unwrap();
      toast.success("Language updated successfuly ");
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.error || e.data);
    }
  };

  useEffect(() => {
    dispatch(
      setCredentials({
        ...userInfo,
        themeColor: themeColor,
      })
    );
  }, [themeColor, selectedLanguage]);

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Change URL ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
      />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <>
          {" "}
          {changePassword ? (
            <ChangePasswordScreen
              user={userInfo}
              changePassword={handleChangePassword}
            ></ChangePasswordScreen>
          ) : editable ? (
            <MyProfileEditScreen
              associate={myDetails}
              editable={handleEditStatus}
            />
          ) : (
            <>
              <h5>
                {preferredLanguage[selectedLanguage]?.myProfile} :{" "}
                {userInfo.firstName} {userInfo.lastName}
              </h5>

              <Row style={{ margin: "2%" }}>
                <Button
                  className=" marginPointEight sixteenWidth buttonPeach twoButtonInRow"
                  onClick={(e) => setEditable(true)}
                >
                  {preferredLanguage[selectedLanguage]?.commonTitles.edit}
                </Button>

                <Button
                  className=" marginPointEight sixteenWidth buttonTeal twoButtonInRow"
                  onClick={(e) => setChangePassword(true)}
                >
                  {
                    preferredLanguage[selectedLanguage]?.commonTitles
                      .changePassword
                  }
                </Button>
              </Row>

              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .adminId
                            }{" "}
                            :{" "}
                          </Col>
                          <Col> {userInfo.associateId}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.role
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.role}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .gender
                            }{" "}
                            :{" "}
                          </Col>
                          <Col> {userInfo.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .shift
                            }{" "}
                            :{" "}
                          </Col>
                          <Col> {userInfo.workShift}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.joinedDate
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.joinedDate?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.dateOfBirth
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.dateOfBirth?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .mobileNumber
                            }
                            :{" "}
                          </Col>
                          <Col> {userInfo.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .mobileNumber
                            }
                            {" 2"}:{" "}
                          </Col>
                          <Col> {userInfo.alternateNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .email
                            }{" "}
                            :{" "}
                          </Col>
                          <Col> {userInfo.email}</Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.pan
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.panNumber}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.aadharNumber
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.aadharNumber}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .address
                            }{" "}
                            :{" "}
                          </Col>
                          <Col> {userInfo.address}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {" "}
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.pincode
                              }{" "}
                              :{" "}
                            </Col>
                            <Col> {userInfo.pincode}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>

              <Row>
                {" "}
                <Col md={5} style={{ marginTop: "3%" }}>
                  <Card>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        margin: "2%",
                      }}
                    >
                      <h6
                        style={{
                          color: "black",
                        }}
                      >
                        {
                          preferredLanguage[selectedLanguage]?.commonTitles
                            .selectTheme
                        }
                      </h6>
                    </Row>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        margin: "3%",
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "#144589" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("blue")}
                      >
                        Blue
                      </Button>
                      <Button
                        style={{ backgroundColor: "#ae6715" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("bronze")}
                      >
                        Bronze
                      </Button>
                      <Button
                        style={{ backgroundColor: "#124137" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("teal")}
                      >
                        Teal
                      </Button>
                      <Button
                        style={{ backgroundColor: "#8f13b1" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("purple")}
                      >
                        Purple
                      </Button>
                      <Button
                        style={{ backgroundColor: "#0a5a6a" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("cyan")}
                      >
                        Cyan
                      </Button>
                      <Button
                        style={{ backgroundColor: "#836e11" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("brown")}
                      >
                        Brown
                      </Button>

                      <Button
                        style={{ backgroundColor: "#8f370e" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("rust")}
                      >
                        Rust
                      </Button>
                      <Button
                        style={{ backgroundColor: "#84117c" }}
                        className="tentyFourWidth"
                        onClick={(e) => setThemeColor("magenta")}
                      >
                        Magenta
                      </Button>
                    </Row>
                    <br />
                    <br />
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "3%",
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "orange" }}
                        className={`marginPointEight tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={handleSubmitColor}
                      >
                        {
                          preferredLanguage[selectedLanguage]?.commonTitles
                            .submit
                        }
                      </Button>
                    </Row>
                  </Card>
                </Col>{" "}
                <Col md={5} style={{ marginTop: "3%" }}>
                  <Card>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        margin: "2%",
                      }}
                    >
                      <h6
                        style={{
                          color: "black",
                        }}
                      >
                        {
                          preferredLanguage[selectedLanguage]?.commonTitles
                            .selectLaguage
                        }
                      </h6>
                    </Row>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        margin: "3%",
                      }}
                    >
                      <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("English")}
                      >
                        English
                      </Button>
                      <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("Tamil")}
                      >
                        தமிழ்
                      </Button>

                      {/* <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("Telugu")}
                      >
                        తెలుగు
                      </Button>
                      <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("Malayalam")}
                      >
                        മലയാളം
                      </Button>
                      <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("Kannada")}
                      >
                        ಕನ್ನಡ
                      </Button>
                      <Button
                        style={{ margin: "2px" }}
                        className={`tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={(e) => updateLanguage("Hindi")}
                      >
                        हिन्दी
                      </Button> */}
                    </Row>

                    <br />
                    <br />
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "3%",
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "orange" }}
                        className={`marginPointEight tentyFourWidth ${userInfo.themeColor}ThemeHeaderTop`}
                        onClick={handleSubmitLanguage}
                      >
                        {
                          preferredLanguage[selectedLanguage]?.commonTitles
                            .submit
                        }
                      </Button>
                    </Row>
                  </Card>
                </Col>{" "}
                {userInfo.role === "owner" ||
                (userInfo.role === "Super Admin" && myGym?.tokenForUsers) ? (
                  <Col md={5} style={{ marginTop: "3%" }}>
                    <Card>
                      <ListGroup>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              {isGymLoading ? (
                                <Loader />
                              ) : errorInMyGym ? (
                                <></>
                              ) : (
                                <label style={{ color: "black" }}>
                                  <strong>
                                    {pageDomain}/usersAtGym/
                                    {myGym.tokenForUsers}
                                  </strong>{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              {" "}
                              <Button
                                className={`${userInfo.themeColor}ThemeHeaderTop`}
                                onClick={() =>
                                  copyUrlClipboard(
                                    `${pageDomain}/usersAtGym/${myGym.tokenForUsers}`
                                  )
                                }
                              >
                                copy url
                              </Button>
                            </Col>
                            <Col>
                              {" "}
                              <Button
                                className={`${userInfo.themeColor}ThemeHeaderLeft`}
                                onClick={handleSubmit}
                              >
                                update url
                              </Button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyProfileScreen;
