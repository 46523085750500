import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { useResetPasswordMutation } from "../slices/associatesSlice";
import RegistrationContainer from "../components/RegistrationContainer";

const ForgotPasswordScreen = () => {
  const [associateId, setAssociateId] = useState();
  const [otp, setOtp] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPwdVisible, setNewPwdVisible] = useState(false);
  const [confirmPwdVisible, setConfirmPwdVisible] = useState(false);

  const navigate = useNavigate();

  // const { userInfo } = useSelector((state) => state.auth);
  const [changeUserPassword, { isLoading }] = useResetPasswordMutation();
  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (associateId?.length !== 8)
      toast.error("Please enter valid 8 digit Associate Id");
    else if (otp?.length !== 4) toast.error("Please enter valid OTP");
    else if (newPassword?.length < 8)
      toast.error("Password Should contain atleast 8 letters");
    else if (newPassword !== confirmNewPassword)
      toast.error("Password and confirm Password are not matching");
    else
      try {
        const res = await changeUserPassword({
          associateId,
          payload: { otp, newPassword, confirmNewPassword },
        }).unwrap();
        if (res)
          toast.success(
            "Password Changed Successfully, Please Login with new password"
          );
        else toast.error(e.data?.error);
        navigate("/login");
      } catch (e) {
        toast.error(e.data?.error);
      }
  };

  const handleCancelButton = () => {
    navigate("/login");
  };

  const setNewPwdVisibility = () => {
    setNewPwdVisible(!newPwdVisible);
  };
  const setConfirmPwdVisibility = () => {
    setConfirmPwdVisible(!confirmPwdVisible);
  };

  return (
    <>
      <RegistrationContainer>
        <h5> Reset Password</h5> <br></br> <br></br>
        {/* <label style={{ color: "red" }}>
          click "Get OTP" button,you will recieve OTP to your registered mobile
          number. then enter the recieved OTP to change the password{" "}
        </label> */}
        <Form onSubmit={submitHandler}>
          <Row>
            {" "}
            {/* <Form.Group as={Row} className="my-2" controlId="password">
              <Form.Label column="true" sm="4"></Form.Label>
              <Col sm="5">
                <Button onClick={setCurrentPwdVisibility}>Get OTP</Button>
              </Col>{" "}
            </Form.Group> */}
            <Form.Group as={Row} className="my-2" controlId="associateId">
              <Form.Label column="true" sm="4">
                Enter Associate Id :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type="text"
                    pattern="[0-9]*" // Allow only digits
                    value={associateId}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setAssociateId(value);
                    }}
                    placeholder="Enter Associate Id"
                  />
                </div>
              </Col>{" "}
            </Form.Group>
            <Form.Group as={Row} className="my-2" controlId="password">
              <Form.Label column="true" sm="4">
                Enter recieved OTP :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type="text"
                    pattern="[0-9]*" // Allow only digits
                    value={otp}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setOtp(value);
                    }}
                    placeholder="enter OTP"
                  />
                </div>
              </Col>{" "}
            </Form.Group>
            <Form.Group as={Row} className="my-2" controlId="newPassword">
              <Form.Label column="true" sm="4">
                New Password :
              </Form.Label>{" "}
              <Col sm="5">
                {" "}
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={newPwdVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>
                  <Button
                    className="cyanThemeHeaderTop"
                    onClick={setNewPwdVisibility}
                  >
                    {newPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="my-2"
              controlId="confirmNewPassword"
            >
              <Form.Label column="true" sm="4">
                Confirm New Password :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={confirmPwdVisible ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>{" "}
                  <Button
                    className="cyanThemeHeaderTop"
                    onClick={setConfirmPwdVisibility}
                  >
                    {confirmPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Row column="true" sm="6">
              <Col sm="4"></Col>
              <Col sm="5">
                <Button
                  className="cyanThemeHeaderTop"
                  type="submit"
                  disabled={isLoading}
                  variant="primary"
                >
                  {" "}
                  Submit
                </Button>{" "}
                <Button
                  style={{ marginLeft: "15%" }}
                  type="button"
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleCancelButton}
                >
                  {" "}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </RegistrationContainer>
    </>
  );
};

export default ForgotPasswordScreen;
