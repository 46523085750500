import { React, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

import Message from "../components/Message";
import { useGetAssociatesQuery } from "../slices/associatesSlice";
import preferredLanguage from "../assets/preferredLanguage.json";
import AssociateRegisterScreen from "./AssociateRegisterScreen";

const AssociatesScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [showAdd, setShowAdd] = useState(false);
  const [reload, setReload] = useState(false);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetAssociatesQuery({
    token: userInfo.token,
  });

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users.associates : []
  );

  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users.associates : []
  );

  useEffect(() => {
    refetch();
    setTotalUsers(isLoading ? [] : users ? users.associates : []);
    setUsersToList(isLoading ? [] : users ? users.associates : []);
  }, [refetch, users, reload]);

  // const handleSelect = (e) => {
  //   setSearchBy(e.target.value);
  // };
  // const onSearchTextChange = (val) => {
  //   setSearchValue(val);
  //   const searchedUsers = users.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };
  // const handleSearchButton = () => {
  //   const searchedUsers = users.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const handleBackButton = async () => {
    setShowAdd(!showAdd);
    setReload(!reload);
  };

  return (
    <>
      {showAdd ? (
        <>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            style={{
              whiteSpace: "nowrap",
              margin: "1%",
            }}
            onClick={(e) => handleBackButton()}
          >
            Back
          </Button>
          <AssociateRegisterScreen />
        </>
      ) : (
        <>
          <h6>Admin List</h6>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            style={{
              whiteSpace: "nowrap",
              margin: "1%",
            }}
            onClick={() => handleFilterSelecttion(totalUsers)}
          >
            Total Admins :<strong> {totalUsers?.length}</strong>
          </Button>
          {userInfo.role === "owner" || userInfo.role === "Super Admin" ? (
            <Button
              style={{
                whiteSpace: "nowrap",
                margin: "1%",
              }}
              className="marginPointOne sixteenWidth buttonOrange numbersButtonDashboard"
              onClick={(e) => setShowAdd(!showAdd)}
              variant="primary"
            >
              {preferredLanguage[selectedLanguage]?.createAdmin}
            </Button>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <div style={{ height: "100vh" }}>
              <div className="tableContainer">
                <Table
                  striped
                  bordered
                  hover
                  responsive="sm"
                  className="table-custom"
                >
                  <thead className={`${userInfo.themeColor}ThemeThead`}>
                    <tr>
                      <th>Name</th>
                      <th>Id</th>
                      <th>Role</th>
                      <th>Shift</th>
                    </tr>
                  </thead>
                  {usersToList ? (
                    <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                      {usersToList.map((user) => (
                        <tr>
                          <td>
                            <Link to={`/associate/${user.associateId}`}>
                              {user.firstName}
                            </Link>
                          </td>

                          <td> {user.associateId}</td>
                          <td> {user.role}</td>

                          <td> {user.workShift}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <Message>No users Available</Message>
                  )}
                </Table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AssociatesScreen;
