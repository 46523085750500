import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  ListGroup,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import Message from "../components/Message";

import UserEditScreen from "./UserEditScreen";
import { useGetUserQuery } from "../slices/usersSlice";
import Loader from "../components/Loader";
import RenewalScreen from "./RenewalScreen";
import PaymentScreen from "./PaymentScreen";
import UpdateExpiryScreen from "./UpdateExpiryScreen";
import ProfileImage from "../components/ProfileImage";
import { useUploadPhotoMutation } from "../slices/usersSlice";
import generateInvoicePDF from "../utils/generateInvoice";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const UserProfileScreen = () => {
  const { id: userId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [selectedLanguage, setSelectedLanguage] = useState(
    userInfo.preferredLanguage ? userInfo.preferredLanguage : "English"
  );
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);

  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(userInfo.lastVisitedPage);
  };

  const {
    data: user,
    isLoading,
    refetch,
  } = useGetUserQuery({ payLoad: userId, token: userInfo.token });

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}`
  );

  const [renewalQuery, setRenewalQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}`
  );

  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: renewals,
    isLoading: renewalsLoading,

    refetch: renewalsRefetch,
  } = useGetRenewalsQuery({
    query: renewalQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();

  const [editable, setEditable] = useState(false);
  const [renewal, setRenewal] = useState(false);
  const [payment, setPayment] = useState(false);
  const [editExpiryDate, setEditExpiryDate] = useState(false);

  const [paymentsToList, setPaymentsToList] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleRenewalStatus = () => {
    setRenewal(false);
  };

  const handlePaymentStatus = () => {
    setPayment(false);
  };

  const handleEditExpiryDateStatus = () => {
    setEditExpiryDate(false);
  };

  useEffect(() => {
    renewalsRefetch();
    paymentsRefetch();
    const allPays = paymentsLoading ? [] : payments;
    setPaymentsToList(allPays);
  }, [payments, renewals, user]);

  useEffect(() => {
    refetch();
  }, [refetch, editable, renewal, payment, editExpiryDate]);

  const compressImage = async (selectedImage) => {
    const file = selectedImage;
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 280,
          useWebWorker: true,
          initialQuality: 1,
        };

        console.log("Un-Compressed file:", file);
        const compressedFile = await imageCompression(file, options);
        // setFile(compressedFile);
        console.log("first Compressed file:", compressedFile);
        const secondOptions = {
          maxSizeMB: 1, // maximum size in MB
          useWebWorker: true, // use web worker for performance
          initialQuality: 0.9, // initial quality setting
        };

        // Second compression step
        const secondCompressedFile = await imageCompression(
          compressedFile,
          secondOptions
        );
        console.log("Second Compressed file:", secondCompressedFile);
        setFile(secondCompressedFile);

        // // You can now upload the compressed file to your server
        // uploadImage(compressedFile);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    const dotIndex = selectedFile?.name?.lastIndexOf(".");
    const fileExtension = selectedFile?.name?.slice(dotIndex + 1).toLowerCase();
    let errorPresent = false;
    if (
      !(
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "tiff" ||
        fileExtension === "svg"
      )
    ) {
      errorPresent = true;
      setFileInvalidError(true);
      setFile(null);
    } else if (selectedFile?.size / 1000 > 5120) {
      setFileSizeError(true);
      setFile(null);
    } else if (!errorPresent && selectedFile?.size / 1000 <= 5120) {
      setFileSizeError(false);
      setFileInvalidError(false);
      await compressImage(selectedFile);
      // setFile(selectedFile);
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append(
          "imageKey",
          `${userInfo?.gymId?._id}${user?._id?.toString().substring(4)}`
        );
        const imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();

        if (imageUploadRes) {
          toast.success(() => (
            <div>
              "Photo uploaded Successfully, Please refresh the page"
              <br />
            </div>
          ));
        }
        setFile(null);
      }
    } catch (e) {
      console.log(e);
      toast.error("Photo upload failed");
    }
  };

  const generateBill = async () => {
    // Example customer and invoice details
    const customerDetails = {
      userId: user?._id,
      userName: user?.firstName + " " + user?.lastName,
      mobileNumber: user?.mobileNumber,
      gymName: userInfo.gymId.name,
    };

    const invoiceDetails = {
      invoiceDate: user?.lastPaidDate,
      description: "Membership Fee",
      sNo: 1,
      subsAmount: user?.subscriptionAmount,
      paidAmount: user?.paidAmount,
      pendingAmount: user?.pendingAmount,
      startDate: user?.renewedDate,
      endDate: user?.subscriptionExpiryDate,
    };

    await generateInvoicePDF(customerDetails, invoiceDetails);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {editable ? (
            <UserEditScreen
              user={user}
              editStatus={handleEditStatus}
            ></UserEditScreen>
          ) : renewal ? (
            <RenewalScreen
              user={user}
              renewalStatus={handleRenewalStatus}
            ></RenewalScreen>
          ) : payment ? (
            <PaymentScreen
              user={user}
              paymentStatus={handlePaymentStatus}
            ></PaymentScreen>
          ) : editExpiryDate ? (
            <UpdateExpiryScreen
              user={user}
              editExpiryDate={handleEditExpiryDateStatus}
            ></UpdateExpiryScreen>
          ) : (
            <>
              {" "}
              {/* <Button
                className={`${userInfo.themeColor}ThemeHeaderTop`}
                onClick={handleBackButton}
              >
                Back
              </Button> */}
              {isPhotoUploading ? (
                <Loader />
              ) : (
                <Row className="my-2">
                  <Col md={10}>
                    <Card style={{ borderStyle: "none" }}>
                      <ProfileImage
                        imageId={
                          userInfo.gymId?._id +
                          "" +
                          user?._id?.toString().substring(4)
                        }
                        setImageError={setImageError}
                      />
                      {imageError ? (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Form.Group
                            as={Row}
                            className="my-1"
                            controlId="photo"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Form.Label column="true" sm="3">
                              Photo not available :
                            </Form.Label> */}
                            <Form.Control
                              style={{ width: "60%" }}
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Col sm="6">
                              {fileSizeError && (
                                <label style={{ color: "red" }}>
                                  Image size should be less than 5 MB
                                </label>
                              )}
                              {fileInvalidError && (
                                <label style={{ color: "red" }}>
                                  Only jpeg, jpg, png, tiff and svg formats are
                                  supported
                                </label>
                              )}
                            </Col>
                          </Form.Group>
                          {file && (
                            <>
                              <Row column="true" sm="6">
                                <Col sm="4"></Col>
                                <Col sm="5">
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    onClick={handleSubmit}
                                  >
                                    {" "}
                                    {
                                      preferredLanguage[selectedLanguage]
                                        ?.commonTitles.submit
                                    }{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          {" "}
                          <Form.Group
                            as={Row}
                            className="my-2"
                            controlId="photo"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Col sm="6">
                              <input type="file" onChange={handleFileChange} />
                              {fileSizeError && (
                                <label style={{ color: "red" }}>
                                  Image size should be less than 5 MB
                                </label>
                              )}
                              {fileInvalidError && (
                                <label style={{ color: "red" }}>
                                  Only jpeg, jpg, png, tiff and svg formats are
                                  supported
                                </label>
                              )}
                            </Col>
                          </Form.Group>
                          {file && (
                            <>
                              <Row column="true" sm="6">
                                <Col sm="4"></Col>
                                <Col sm="5">
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    onClick={handleSubmit}
                                  >
                                    {" "}
                                    {
                                      preferredLanguage[selectedLanguage]
                                        ?.commonTitles.submit
                                    }{" "}
                                    :{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
              <Row style={{ margin: "0.5%" }}>
                <Button
                  className=" marginPointOne sixteenWidth buttonLoveBlue numbersButtonAssociateProfile"
                  onClick={(e) => setRenewal(true)}
                >
                  {preferredLanguage[selectedLanguage]?.commonTitles.renewal}{" "}
                </Button>

                <Button
                  className=" marginPointOne sixteenWidth buttonTeal numbersButtonAssociateProfile"
                  onClick={(e) => setPayment(true)}
                >
                  {preferredLanguage[selectedLanguage]?.commonTitles.payment}{" "}
                </Button>
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ? (
                  <>
                    <Button
                      className=" marginPointOne sixteenWidth buttonReddish numbersButtonAssociateProfile"
                      onClick={(e) => setEditExpiryDate(true)}
                    >
                      {
                        preferredLanguage[selectedLanguage]?.commonTitles
                          .changeValidity
                      }
                    </Button>{" "}
                  </>
                ) : (
                  ""
                )}
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ||
                userInfo.role === "Admin" ? (
                  <Button
                    className=" marginPointOne sixteenWidth buttonMaroon numbersButtonAssociateProfile"
                    onClick={(e) => setEditable(true)}
                  >
                    {
                      preferredLanguage[selectedLanguage]?.commonTitles
                        .changeUserDetails
                    }
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  className=" marginPointOne sixteenWidth buttonGreen numbersButtonAssociateProfile"
                  onClick={generateBill}
                >
                  {
                    preferredLanguage[selectedLanguage]?.commonTitles
                      .downloadBill
                  }
                </Button>
              </Row>
              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .name
                            }{" "}
                          </Col>
                          <Col> {user?.firstName + " " + user?.lastName}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .id
                            }{" "}
                          </Col>
                          <Col> {user?._id?.toString().substring(4)}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .gender
                            }{" "}
                          </Col>
                          <Col> {user?.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.subscriptionType
                            }{" "}
                          </Col>
                          <Col> {user?.subscriptionType}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.offerDetails
                            }{" "}
                          </Col>
                          <Col> {user?.notes}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.personalTrainerAvailed
                            }{" "}
                          </Col>
                          <Col> {user?.ptEnabled ? "Yes" : "No"}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.gymGoal
                            }{" "}
                          </Col>
                          <Col> {user?.gymGoal.join(" , ")}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.subscriptionAmount
                              }{" "}
                            </Col>
                            <Col> {user?.subscriptionAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.paidAmount
                              }{" "}
                            </Col>
                            <Col> {user?.paidAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row
                            style={{
                              color: user?.pendingAmount > 0 ? "red" : "",
                            }}
                          >
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.paymentsListPage.pending
                              }{" "}
                            </Col>
                            <Col> {user?.pendingAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.paidDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.lastPaidDate
                                ? user?.lastPaidDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.dateOfBirth
                            }{" "}
                          </Col>
                          <Col> {user?.dateOfBirth?.split("T")[0]}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.joinedDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.joinedDate
                                ? user?.joinedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.renewedDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.renewedDate
                                ? user?.renewedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .validityTill
                            }{" "}
                          </Col>
                          <Col>
                            {" "}
                            {user?.subscriptionExpiryDate
                              ? user?.subscriptionExpiryDate?.split("T")[0]
                              : ""}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .aadharNumber
                            }{" "}
                          </Col>
                          <Col> {user?.aadharNumber}</Col>
                        </Row>
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .heigthInCm
                            }{" "}
                          </Col>
                          <Col> {user?.heigthInCm}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .weightInKg
                            }{" "}
                          </Col>
                          <Col> {user?.weightInKg}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .mobileNumber
                            }{" "}
                          </Col>
                          <Col> {user?.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .email
                            }{" "}
                          </Col>
                          <Col> {user?.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.address
                              }{" "}
                            </Col>
                            <Col> {user?.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>Created Mode :</Col>
                          <Col>
                            {" "}
                            {user?.createdByUpload
                              ? "Bulk Upload"
                              : "Manual Registration"}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .updatedBy
                            }{" "}
                          </Col>
                          <Col> {user?.updatedBy}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <hr
                style={{
                  border: "1px solid #ccc",
                  margin: "5px 0",
                }}
              />
              {paymentsLoading ? (
                <Loader />
              ) : (
                <Row className="my-2">
                  <Col>
                    <Card style={{ border: "none" }}>
                      <label
                        style={{
                          margin: "auto",
                          marginTop: "5px",
                          color: "black",
                        }}
                      >
                        {" "}
                        <strong>
                          {preferredLanguage[selectedLanguage]?.paymentsList}{" "}
                        </strong>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          margin: "auto",
                          marginTop: "7px",
                          justifyContent: "center",
                          width: "100%",
                          justifyContent: "space-around",
                        }}
                      >
                        {paymentsLoading && renewalsLoading ? (
                          <Loader />
                        ) : paymentsError ? (
                          <Message>{paymentsError?.data?.error}</Message>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              margin: "auto",
                              justifyContent: "center",
                              width: "100%",

                              justifyContent: "space-around",
                            }}
                          >
                            <div className="tableContainer">
                              <Table
                                striped
                                bordered
                                hover
                                responsive="sm"
                                className="table-custom"
                              >
                                <thead
                                  className={`${userInfo.themeColor}ThemeThead`}
                                >
                                  <tr>
                                    <th>Subscription Amount</th>
                                    <th>Subscription Date</th>
                                    <th>Paid Amount</th>
                                    <th>Paid Date</th>
                                    <th>TransactionID</th>
                                  </tr>
                                </thead>
                                {paymentsToList &&
                                paymentsToList?.length > 0 ? (
                                  <tbody
                                    className={`${userInfo.themeColor}ThemeTbody`}
                                  >
                                    {paymentsToList.map((user) => (
                                      <tr
                                        key={user.userId + "" + user.createdAt}
                                      >
                                        <td>{user.subscriptionAmount}</td>
                                        <td>
                                          {user.renewedDate?.split("T")[0]}
                                        </td>
                                        <td>{user.paidAmount}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          {user.paidDate
                                            ? user.paidDate?.split("T")[0]
                                            : user.lastPaidDate?.split("T")[0]}
                                        </td>
                                        <td>{user.transactionId}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <Message>
                                    No Payments Available for the selected
                                    Period
                                  </Message>
                                )}
                              </Table>
                            </div>{" "}
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserProfileScreen;
