import { apiSlice } from "./apiSlice";
import { GYMMETA_URL } from "../constants";

const wsGymMetaSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateWsSettings: builder.mutation({
      query: (data) => ({
        url: `${GYMMETA_URL}/updateWsSettings/${data.gymId}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateWsBalance: builder.mutation({
      query: (data) => ({
        url: `${GYMMETA_URL}/updateWsBalance/${data.gymId}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getMyGymMeta: builder.query({
      query: (body) => ({
        url: `${GYMMETA_URL}/myGymMeta`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),
  }),
});

export const {
  useUpdateWsBalanceMutation,
  useUpdateWsSettingsMutation,
  useGetMyGymMetaQuery,
} = wsGymMetaSlice;
