import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"; // Importing xlsx library
import { toast } from "react-toastify";
import { FaSort } from "react-icons/fa";

import { useGetUsersQuery } from "../slices/usersSlice";
import Loader from "../components/Loader";
import { setUsers } from "../slices/userSlice";
import Message from "../components/Message";
import { setCredentials } from "../slices/authSlice";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import preferredLanguage from "../assets/preferredLanguage.json";

const AllUsersScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  const filterOptions = ["Id", "Name", "Mobile"];

  const dispatch = useDispatch();

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetUsersQuery({
    query: `?sort=-createdAt`,
    payLoad: userInfo.gymId._id,
    token: userInfo.token,
  });

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users : []
  );
  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users : []
  );
  const [activeUsers, setActiveUsers] = useState("");
  const [inActiveUsers, setInactiveUsers] = useState("");
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizens, setSeniorCitizens] = useState("");
  const [ptEnablesUsers, setPtEnablesUsers] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    refetch();
    if (users) dispatch(setUsers(users));
    dispatch(
      setCredentials({
        ...userInfo,
        lastVisitedPage: "/users",
      })
    );

    setTotalUsers(isLoading ? [] : users ? users : 0);
    const allUsers = isLoading ? [] : users ? users : [];

    const sortedUsers =
      sortConfig?.key !== "userId"
        ? [...allUsers]?.sort((a, b) => {
            if (
              a[sortConfig.key]?.toString().toLowerCase() <
              b[sortConfig.key]?.toString().toLowerCase()
            ) {
              return sortConfig?.direction === "asc" ? -1 : 1;
            }
            if (
              a[sortConfig.key]?.toString().toLowerCase() >
              b[sortConfig.key]?.toString().toLowerCase()
            ) {
              return sortConfig?.direction === "asc" ? 1 : -1;
            }
            return 0;
          })
        : [...allUsers]?.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig?.direction === "asc" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig?.direction === "asc" ? 1 : -1;
            }
            return 0;
          });

    setUsersToList(sortedUsers);
    setActiveUsers(
      isLoading ? [] : users ? users.filter((user) => user["isActive"]) : []
    );

    setInactiveUsers(
      isLoading ? [] : users ? users.filter((user) => !user["isActive"]) : []
    );

    setMaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Male")
        : []
    );

    setfemaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Female")
        : []
    );
    setSeniorCitizens(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              50 <
              Math.floor(
                (new Date() - new Date(user["dateOfBirth"])) /
                  (365.25 * 24 * 60 * 60 * 1000)
              )
          )
        : []
    );

    setPtEnablesUsers(
      isLoading ? [] : users ? users.filter((user) => user["ptEnabled"]) : []
    );
  }, [refetch, dispatch, users, sortConfig]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = users?.filter((user) => {
      let userData;
      if (searchBy === "Name") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setUsersToList(searchedUsers);
  };
  // const handleSearchButton = () => {
  //   const searchedUsers = users?.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const formattedData = users?.map((user) => ({
        "User Id": user.userId,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Gender: user.gender,
        "PT Enabled": user.ptEnabled,
        "Subscription Months": user.subscriptionType,
        "Subscription Amount": user.subscriptionAmount,
        "Paid Amount": user.paidAmount,
        "Pending Amount": user.pendingAmount,
        "Recent Paid Date": user.lastPaidDate
          ? user.lastPaidDate.split("T")[0]
          : user.lastPaidDate.split("T")[0],

        "Joined Date": user.joinedDate
          ? user.joinedDate.split("T")[0]
          : user.joinedDate.split("T")[0],
        "Renewed Date": user.renewedDate
          ? user.renewedDate.split("T")[0]
          : user.renewedDate.split("T")[0],
        "Expiry Date": user.subscriptionExpiryDate
          ? user.subscriptionExpiryDate.split("T")[0]
          : user.subscriptionExpiryDate.split("T")[0],
        "Mobile Number": user.mobileNumber,
        "Aadhar Number": user.aadharNumber,
        "Heigth In Cm": user.heigthInCm,
        "Weight In Kg": user.weightInKg,
        Email: user.email,
        "Date of Birth": user.dateOfBirth
          ? user.dateOfBirth.split("T")[0]
          : user.dateOfBirth.split("T")[0],

        "Gym Goal": user.gymGoal[0],
        // isActive: user.isActive,
        Address: user.address,
        "Added By": user.associateId,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Users List");
      XLSX.writeFile(workBook, "Users List.xlsx");
    }
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  return (
    <>
      <label>
        <strong>{preferredLanguage[selectedLanguage]?.allMembers}</strong>
      </label>
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col>
          <Form.Group as={Row} className="my-2" controlId="filterBy">
            <Form.Select id="filterBy" value={searchBy} onChange={handleSelect}>
              {filterOptions.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Form.Control
              type="text"
              placeholder="type here to search"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        {/* <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Button
              type="date"
              placeholder="2023-11-27"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            >
              Search
            </Button>
          </Form.Group>
        </Col> */}
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button onClick={exportToExcel} variant="success">
            {preferredLanguage[selectedLanguage]?.membersPage.downloadUsers}
          </Button>
        </Col>
      </Row>
      <Row
        className="dataRow"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
          marginBottom: "2%",
        }}
      >
        <Button
          className="marginPointOne fifteenWidth buttonGreen numbersButton"
          onClick={() => handleFilterSelecttion(totalUsers)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.total}{" "}
          <strong> {totalUsers?.length}</strong>
        </Button>
        {/* <Button
          className="marginPointOne fifteenWidth buttonPeach numbersButton"
          onClick={() => handleFilterSelecttion(activeUsers)}
        >
          Active <strong> {activeUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonReddish numbersButton"
          onClick={() => handleFilterSelecttion(inActiveUsers)}
        >
          InActive <strong> {inActiveUsers?.length}</strong>
        </Button> */}
        <Button
          className="marginPointOne fifteenWidth buttonTeal numbersButton"
          onClick={() => handleFilterSelecttion(maleUsers)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.male}{" "}
          <strong> {maleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOrchid numbersButton"
          onClick={() => handleFilterSelecttion(femaleUsers)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.female}{" "}
          <strong> {femaleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonGreenish numbersButton"
          onClick={() => handleFilterSelecttion(seniorCitizens)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.seniors}{" "}
          <strong> {seniorCitizens?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOrange numbersButton"
          onClick={() => handleFilterSelecttion(ptEnablesUsers)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.ptUser}{" "}
          <strong> {ptEnablesUsers?.length}</strong>
        </Button>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr key="table-header">
                  <th
                    onClick={() => handleSort("firstName")}
                    style={{ cursor: "pointer" }}
                  >
                    Name <FaSort />
                  </th>
                  <th
                    onClick={() => handleSort("userId")}
                    style={{ cursor: "pointer" }}
                  >
                    Id <FaSort />
                  </th>
                  <th
                    onClick={() => handleSort("subscriptionType")}
                    style={{ cursor: "pointer" }}
                  >
                    Subscription <FaSort />
                  </th>
                  {/* <th>Status</th> */}
                  <th
                    onClick={() => handleSort("subscriptionExpiryDate")}
                    style={{ cursor: "pointer" }}
                  >
                    Valid <FaSort />
                  </th>
                  <th>Added By</th>
                </tr>
              </thead>
              {usersToList ? (
                <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                  {usersToList?.map((user) => (
                    <tr key={user.userId}>
                      <td>
                        <Link
                          style={{
                            color:
                              new Date(user.subscriptionExpiryDate) <
                              new Date(getCurrentDate())
                                ? "red"
                                : user.subscriptionExpiryDate
                                    .split("T")[0]
                                    .toString() === getCurrentDate()
                                ? "#fc9834"
                                : "inherit",
                          }}
                          to={`/users/${user.userId}`}
                        >
                          {user.firstName}
                        </Link>
                      </td>
                      <td
                        style={{
                          color:
                            new Date(user.subscriptionExpiryDate) <
                            new Date(getCurrentDate())
                              ? "red"
                              : user.subscriptionExpiryDate
                                  .split("T")[0]
                                  .toString() === getCurrentDate()
                              ? "#fc9834"
                              : "inherit",
                        }}
                      >
                        {user.userId}
                      </td>

                      <td
                        style={{
                          color:
                            new Date(user.subscriptionExpiryDate) <
                            new Date(getCurrentDate())
                              ? "red"
                              : user.subscriptionExpiryDate
                                  .split("T")[0]
                                  .toString() === getCurrentDate()
                              ? "#fc9834"
                              : "inherit",
                        }}
                      >
                        {" "}
                        {user.subscriptionType}
                      </td>
                      {/* <td> {user.isActive ? "Active" : "Not Active"}</td> */}
                      <td
                        style={{
                          color:
                            new Date(user.subscriptionExpiryDate) <
                            new Date(getCurrentDate())
                              ? "red"
                              : user.subscriptionExpiryDate
                                  .split("T")[0]
                                  .toString() === getCurrentDate()
                              ? "#fc9834"
                              : "inherit",
                        }}
                      >
                        {user.subscriptionExpiryDate
                          ? user.subscriptionExpiryDate?.split("T")[0]
                          : ""}
                      </td>
                      <td
                        style={{
                          color:
                            new Date(user.subscriptionExpiryDate) <
                            new Date(getCurrentDate())
                              ? "red"
                              : user.subscriptionExpiryDate
                                  .split("T")[0]
                                  .toString() === getCurrentDate()
                              ? "#fc9834"
                              : "inherit",
                        }}
                      >
                        {user.associateId}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Message>No users Available</Message>
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default AllUsersScreen;
