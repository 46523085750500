import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { FaSort } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"; // Importing xlsx library
import { useGetUsersQuery, useUpdateUserMutation } from "../slices/usersSlice";
import Loader from "../components/Loader";
import { setUsers } from "../slices/userSlice";
import Message from "../components/Message";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

import { toast } from "react-toastify";

const SubsExpiredUsersScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const [editEnabled, setEditEnabled] = useState("");
  const [notes, setNotes] = useState("");
  const [apiQuery, setApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&subscriptionExpiryDate[lt]=${getDateOfLast(
      -45
    )}&sort=-subscriptionExpiryDate`
  );
  const filterOptions = ["Id", "Name", "Mobile"];

  const thisMonthStart = getCurrentDate().substring(0, 8) + "01";
  const thisMonthEnd = getCurrentDate().substring(0, 8) + "32";

  const dispatch = useDispatch();

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };

  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetUsersQuery({
    token: userInfo.token,
    query: apiQuery,
  });

  const [updateUser] = useUpdateUserMutation();

  const [totalUsers, setTotalUsers] = useState(
    isLoading
      ? []
      : users
      ? users.filter(
          (user) => user["subscriptionExpiryDate"] < getCurrentDate()
        )
      : []
  );
  const [usersToList, setUsersToList] = useState(
    isLoading
      ? []
      : users
      ? users.filter(
          (user) => user["subscriptionExpiryDate"] < getCurrentDate()
        )
      : []
  );
  const [activeUsers, setActiveUsers] = useState("");
  const [inActiveUsers, setInactiveUsers] = useState("");
  const [thisMonth, setThisMonth] = useState("");
  const [nextTwoWeeks, setNextTwoWeeks] = useState("");
  const [lastTwoWeeks, setLastTwoWeeks] = useState("");
  const [ptEnablesUsers, setPtEnablesUsers] = useState("");

  useEffect(() => {
    refetch();
    if (users) dispatch(setUsers(users));
    dispatch(
      setCredentials({
        ...userInfo,
        lastVisitedPage: "/subsExpiredUsers",
      })
    );
    setTotalUsers(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) => user["subscriptionExpiryDate"] < getCurrentDate()
          )
        : 0
    );
    const allUsers = isLoading ? [] : users ? users : [];

    setUsersToList(
      allUsers.filter(
        (user) => user["subscriptionExpiryDate"] < getCurrentDate()
      )
    );
    setActiveUsers(
      isLoading ? [] : users ? users.filter((user) => user["isActive"]) : []
    );

    setInactiveUsers(
      isLoading ? [] : users ? users.filter((user) => !user["isActive"]) : []
    );

    setThisMonth(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              user["subscriptionExpiryDate"] >= thisMonthStart &&
              user["subscriptionExpiryDate"] <= thisMonthEnd
          )
        : []
    );

    setNextTwoWeeks(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              user["subscriptionExpiryDate"] >= getCurrentDate() &&
              user["subscriptionExpiryDate"] <= getDateOfLast(-8)
          )
        : []
    );
    setLastTwoWeeks(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              user["subscriptionExpiryDate"] <= getCurrentDate() &&
              user["subscriptionExpiryDate"] >= getDateOfLast(8)
          )
        : []
    );

    setPtEnablesUsers(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              user["subscriptionExpiryDate"] < getCurrentDate() &&
              user["ptEnabled"]
          )
        : []
    );
  }, [refetch, dispatch, users, editEnabled]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = users?.filter((user) => {
      let userData;
      if (searchBy === "Name") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setUsersToList(searchedUsers);
  };
  // const handleSearchButton = () => {
  //   const searchedUsers = users?.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const hanbdleSubmit = async (user) => {
    try {
      const userId = editEnabled?.toString().substring(4);
      await updateUser({
        userId,
        token: userInfo.token,
        payLoad: {
          notes,
        },
      }).unwrap();
      setEditEnabled("");
      setNotes("");
      toast.success("User Details updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };
  const hanbdleCancelEdit = () => {
    setEditEnabled(false);
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const formattedData = users?.map((user) => ({
        "User Id": user.userId,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Gender: user.gender,
        "PT Enabled": user.ptEnabled,
        "Subscription Months": user.subscriptionType,
        "Subscription Amount": user.subscriptionAmount,
        "Paid Amount": user.paidAmount,
        "Pending Amount": user.pendingAmount,
        "Recent Paid Date": user.lastPaidDate
          ? user.lastPaidDate.split("T")[0]
          : user.lastPaidDate.split("T")[0],

        "Joined Date": user.joinedDate
          ? user.joinedDate.split("T")[0]
          : user.joinedDate.split("T")[0],
        "Renewed Date": user.renewedDate
          ? user.renewedDate.split("T")[0]
          : user.renewedDate.split("T")[0],
        "Expiry Date": user.subscriptionExpiryDate
          ? user.subscriptionExpiryDate.split("T")[0]
          : user.subscriptionExpiryDate.split("T")[0],
        "Mobile Number": user.mobileNumber,
        "Aadhar Number": user.aadharNumber,
        "Heigth In Cm": user.heigthInCm,
        "Weight In Kg": user.weightInKg,
        Email: user.email,
        "Date of Birth": user.dateOfBirth
          ? user.dateOfBirth.split("T")[0]
          : user.dateOfBirth.split("T")[0],

        "Gym Goal": user.gymGoal[0],
        // isActive: user.isActive,
        Address: user.address,
        "Added By": user.associateId,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Users List");
      XLSX.writeFile(workBook, "Users List.xlsx");
    }
  };

  return (
    <>
      <label>
        <strong>
          {preferredLanguage[selectedLanguage]?.membersPage.inactiveMembers}
        </strong>
      </label>
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col>
          <Form.Group as={Row} className="my-2" controlId="filterBy">
            <Form.Select id="filterBy" value={searchBy} onChange={handleSelect}>
              {filterOptions.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Form.Control
              type="text"
              placeholder="type here to search"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        {/* <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Button
              type="date"
              placeholder="2023-11-27"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            >
              Search
            </Button>
          </Form.Group>
        </Col> */}
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button onClick={exportToExcel} variant="success">
            {
              preferredLanguage[selectedLanguage]?.membersPage
                .downloadInactiveUsers
            }
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
          marginBottom: "1%",
        }}
        className="dataRow"
      >
        <Button
          className="marginPointOne fifteenWidth buttonGreen numbersButton"
          onClick={() => handleFilterSelecttion(totalUsers)}
        >
          {preferredLanguage[selectedLanguage]?.expiringMembersPage.expired}{" "}
          {" : "}
          <strong> {totalUsers?.length}</strong>
        </Button>
        {/* <Button
          className="marginPointOne fifteenWidth buttonPeach numbersButton"
          onClick={() => handleFilterSelecttion(activeUsers)}
        >
          Active <strong> {activeUsers?.length}</strong>
        </Button> */}
        {/* <Button
          className="marginPointOne fifteenWidth buttonReddish numbersButton"
          onClick={() => handleFilterSelecttion(inActiveUsers)}
        >
          InActive <strong> {inActiveUsers?.length}</strong>
        </Button> */}
        <Button
          className="marginPointOne fifteenWidth buttonTeal numbersButton"
          onClick={() => handleFilterSelecttion(thisMonth)}
        >
          {preferredLanguage[selectedLanguage]?.expiringMembersPage.thisMonth}
          {" : "}
          <strong> {thisMonth?.length}</strong>
        </Button>

        <Button
          className="marginPointOne fifteenWidth buttonGreenish numbersButton"
          onClick={() => handleFilterSelecttion(lastTwoWeeks)}
        >
          {
            preferredLanguage[selectedLanguage]?.expiringMembersPage
              .lastTwoWeeks
          }{" "}
          {" : "}
          <strong> {lastTwoWeeks?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOrchid numbersButton"
          onClick={() => handleFilterSelecttion(nextTwoWeeks)}
        >
          {
            preferredLanguage[selectedLanguage]?.expiringMembersPage
              .nextTwoWeeks
          }{" "}
          {" : "}
          <strong> {nextTwoWeeks?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOrange numbersButton"
          onClick={() => handleFilterSelecttion(ptEnablesUsers)}
        >
          {preferredLanguage[selectedLanguage]?.membersPage.ptUser} {" : "}
          <strong> {ptEnablesUsers?.length}</strong>
        </Button>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Subscription</th>
                  <th>Expiring On</th>
                  <th>Mobile</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                {usersToList.map((user) => (
                  <tr key={user.userId}>
                    <td>
                      <Link to={`/users/${user.userId}`}>{user.firstName}</Link>
                    </td>
                    <td>{user.userId}</td>

                    <td> {user.subscriptionType}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {user.subscriptionExpiryDate
                        ? user.subscriptionExpiryDate?.split("T")[0]
                        : ""}
                    </td>
                    <td>{user.mobileNumber}</td>
                    <td>
                      {editEnabled === user._id ? (
                        <Form.Control
                          value={notes}
                          type="text"
                          placeholder="type here"
                          onChange={(e) => setNotes(e.target.value)}
                        ></Form.Control>
                      ) : (
                        <>{user.notes}</>
                      )}
                    </td>
                    <td>
                      {editEnabled === user._id ? (
                        <>
                          <button onClick={hanbdleSubmit}>
                            <CheckLg />
                          </button>
                          <button
                            onClick={hanbdleCancelEdit}
                            style={{
                              borderRadius: "10%",
                              margin: "8%",
                            }}
                          >
                            <XLg />
                          </button>
                        </>
                      ) : (
                        <button
                          style={{ borderRadius: "20%" }}
                          onClick={() => {
                            setEditEnabled(user._id);
                            setNotes(user.notes);
                          }}
                        >
                          <Pencil />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default SubsExpiredUsersScreen;
