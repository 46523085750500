import React from "react";

function FieldWarning({ text }) {
  return (
    <div>
      <strong>
        <p
          style={{
            fontSize: "0.9rem",
            color: "orange",
          }}
        >
          {text}
        </p>
      </strong>
    </div>
  );
}

export default FieldWarning;
