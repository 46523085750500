import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useChangeAssociateRoleMutation } from "../slices/associatesSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import RegistrationContainer from "../components/RegistrationContainer";

const AssociateChangeRoleScreen = ({ user, changeRole }) => {
  const [associateId, setAssociateId] = useState(user.associateId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [role, setRole] = useState(user.role);
  const currentRole = useState(user.role);
  const [modalShow, setModalShow] = useState(false);

  const availableRoles = ["Associate", "Admin", "Super Admin"];
  const roleIds = { "Super Admin": 1, Admin: 2, Associate: 3 };

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [changeUserRole, { isLoading }] = useChangeAssociateRoleMutation();

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      await changeUserRole({
        associateId,
        payload: { role, roleId: roleIds[role] },
        token: userInfo.token,
      }).unwrap();
      navigate(`/associate/${user.associateId}`);
      changeRole();
      toast.success("User Role Updated Successfully");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleCancelButton = () => {
    navigate(`/associate/${user.associateId}`);
    changeRole();
  };

  const handleAssociateRoleChange = () => {
    setModalShow(true);
  };

  return (
    <RegistrationContainer>
      <h5>
        {" "}
        Change Role for :{" "}
        <strong>
          {firstName} {lastName}
        </strong>
      </h5>
      <VerticallyCenteredModal
        title="Are you sure want to do this role change ?"
        payload={{ "Current Role": currentRole, "New Role": role }}
        size="md"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      />

      <Row>
        {" "}
        <Form.Group as={Row} className="my-2" controlId="associateRole">
          <Form.Label column="true" sm="3">
            Admin Role *:
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              placeholder="Select Associate Role"
            >
              {availableRoles.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <Row column="true" sm="6">
          <Col sm="4"></Col>
          <Col sm="5">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              type="submit"
              disabled={isLoading}
              variant="primary"
              onClick={handleAssociateRoleChange}
            >
              {" "}
              Submit
            </Button>{" "}
            <Button
              style={{ marginLeft: "15%" }}
              type="button"
              disabled={isLoading}
              variant="secondary"
              onClick={handleCancelButton}
            >
              {" "}
              Cancel
            </Button>
          </Col>
        </Row>
      </Row>
    </RegistrationContainer>
  );
};

export default AssociateChangeRoleScreen;
