import { useSelector } from "react-redux";
import QRCodeGenerator from "../utils/createQRcode";
import FormContainer from "../components/FormContainer";

const QrCodeForRegScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const qrContent = `https://mygym.zpotter.in/userSelfRegistration/${userInfo.gymId._id}`;

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <FormContainer>
        <h4 style={{ color: "black" }}>
          Scan and provide user details to Register
        </h4>
        <QRCodeGenerator content={qrContent} />
      </FormContainer>
    </div>
  );
};

export default QrCodeForRegScreen;
