import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useUpdateUserMutation } from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import preferredLanguage from "../assets/preferredLanguage.json";

const UpdateExpiryScreen = ({ user, editExpiryDate }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [userId, setUserId] = useState(user.userId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [subscriptionType, setSubscriptionType] = useState(
    user.subscriptionType
  );
  const [subsExpiryDate, setSubsExpiryDate] = useState(
    user.subscriptionExpiryDate?.split("T")[0]
  );
  const [modalShow, setModalShow] = useState(false);
  const [offerDetails, setOfferDetails] = useState(user.notes);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  // const [renewedDatePresent, setRenewedDatePresent] = useState(false);

  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [subscriptionDatePresent, setSubscriptionDatePresent] = useState(false);

  const subscriptionList = [
    "Select Months",
    "1 Month",
    "3 Months",
    "6 Months",
    "12 Months",
  ];

  const navigate = useNavigate();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }
  }, [subscriptionType]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (subsExpiryDate < getCurrentDate()) {
      setSubscriptionDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async () => {
    // e.preventDefault();

    try {
      await updateUser({
        userId,
        payLoad: {
          subscriptionType,
          subscriptionExpiryDate: subsExpiryDate,
          notes: offerDetails,
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/users/${user.userId}`);
      editExpiryDate();
      toast.success("Expiry Date is updated Successfully");
    } catch (e) {
      toast.error(e?.data?.message || e.data);
    }
  };

  const handleCancelButton = () => {
    navigate(`/users/${user.userId}`);
    editExpiryDate();
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      {" "}
      <h1> Update Subscription </h1>
      <VerticallyCenteredModal
        title="Are you sure want to Update expiry date ?"
        size="lg"
        payload={{
          "Subscription Type": subscriptionType,
          "Subscription Expiry Date": subsExpiryDate,
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      />
      <Row>
        <Col md={8}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              ID :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{userId}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              Name :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{firstName}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="4">
              Subscription Type * :
            </Form.Label>
            <Col sm="5">
              <Form.Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="expiryDate">
            <Form.Label column="true" sm="4">
              Expiry Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={subsExpiryDate}
                onChange={(e) => setSubsExpiryDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {subscriptionDatePresent ? (
                <FieldWarning text="Expiry date can not be past date " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .offerDetails
              }
              :
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                value={offerDetails}
                onChange={(e) => setOfferDetails(e.target.value)}
                placeholder="buy 3 months, 1 months free"
              ></Form.Control>
            </Col>
          </Form.Group>
          <Row column="true" sm="6">
            <Col sm="4"></Col>
            <Col sm="5">
              <Button
                className={`${userInfo.themeColor}ThemeHeaderTop`}
                type="submit"
                disabled={isLoading}
                variant="primary"
                onClick={hanbdleSubmit}
              >
                {" "}
                Submit
              </Button>{" "}
              <Button
                style={{ marginLeft: "20%" }}
                type="button"
                disabled={isLoading}
                variant="secondary"
                onClick={handleCancelButton}
              >
                {" "}
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UpdateExpiryScreen;
