const ContactUsScreen = () => {
  return (
    <div style={{ textAlign: "center", justifyContent: "center" }}>
      {" "}
      <h5>
        Incase of Any issues or query, Please contact us in the below email or
        mobile numbers
      </h5>
      <h4>
        Email : <label>contactus@zpotter.in</label> <br></br>
        <label>zpottertechnologies@gmail.com</label>
      </h4>
      <br></br>
      <h4>
        Mobile : 7806873973<br></br>
      </h4>
    </div>
  );
};

export default ContactUsScreen;
