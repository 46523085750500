import { Button, Form, Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import FieldWarning from "../components/FieldWarning";
import VerticallyCenteredModal from "../components/SubmitModal";
import { useUpdateMyGymMutation } from "../slices/gymSlice";

const GymProfileEditScreen = ({ associate: gymDetails, editable }) => {
  // const [regCode, setRegCode] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const [gymName, setGymName] = useState(gymDetails.name);
  const [ownerName, setOwnerName] = useState(gymDetails.ownerName);

  const [email, setEmail] = useState(gymDetails.email);
  const [contactNumber, setConatctNumber] = useState(gymDetails.primaryContact);
  const [alternateNumber, setAlternateNumber] = useState(
    gymDetails.secondaryContacts
  );
  const [address, setAddress] = useState(gymDetails.address);
  const [pincode, setPincode] = useState(gymDetails.pincode);

  const [subscriptionType, setSubscriptionType] = useState(
    gymDetails.subscriptionType
  );

  const [modalShow, setModalShow] = useState(false);

  const [gymNamePresent, setGymNamePresent] = useState(gymDetails.name);
  const [ownerNamePresent, setOwnerNamePresent] = useState(
    gymDetails.ownerName
  );

  const [emailPresent, setEmailPresent] = useState(gymDetails.email);
  const [contactNumberPresent, setConatctNumberPresent] = useState(
    gymDetails.primaryContact
  );

  const [addressPresent, setAddressPresent] = useState(gymDetails.address);
  const [pincodePresent, setPincodePresent] = useState(gymDetails.pincode);

  const subscriptionTypes = ["Premium", "Premium Plus", "Yearly"];

  const [updateGymDetails, { isLoading }] = useUpdateMyGymMutation();

  useEffect(() => {
    if (gymName?.length > 0) {
      setGymNamePresent(false);
    }
    if (ownerName?.length > 0) {
      setOwnerNamePresent(false);
    }

    if (contactNumber?.length === 10 && contactNumber > 1) {
      setConatctNumberPresent(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) || email?.length === 0) {
      setEmailPresent(false);
    }

    if (address?.length >= 10) {
      setAddressPresent(false);
    }

    if (pincode?.toString().length === 6) {
      setPincodePresent(false);
    }
  }, [
    gymName,
    ownerName,
    email,

    address,
    contactNumber,
    pincode,
    alternateNumber,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (gymName?.length < 1 || gymName?.length > 100) {
      setGymNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (ownerName?.length > 100) {
      setOwnerNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (contactNumber?.toString().length !== 10 || contactNumber < 1) {
      setConatctNumberPresent(true);
      fieldValidationSuccess = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (address?.length < 10 || address?.length > 1000) {
      setAddressPresent(true);
      fieldValidationSuccess = false;
    }

    if (pincode?.toString().length !== 6 || pincode < 1) {
      setPincodePresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      const data = {
        payload: {
          name: gymName,
          ownerName,
          primaryContact: contactNumber,
          secondaryContacts: alternateNumber,
          email,
          pincode,
          address,
          subscriptionType,
        },
        token: userInfo.token,
      };
      await updateGymDetails(data).unwrap();
      editable();
      toast.success("Gym details updated successfuly ");
      // setFirstName("");
      // setLastName("");
      // setGender("Male");
      // setEmail("");
      // setConatctNumber("");
      // setAlternateNumber("");
      // setAddress("");
      // setPincode("");
      // setAadharNumber("");
      // setPanNumber("");
      // setDateOfBirth(getCurrentDate());
      // setJoinedDate(getCurrentDate());
      // navigate("/");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleCancelButton = () => {
    // navigate(`/profile`);
    editable();
  };

  const handleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to submit this ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />
      <Row>
        <h4>Edit Gym Profile : </h4>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="firstName">
            <Form.Label column="true" sm="3">
              Gym Name <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={gymName}
                onChange={(e) => setGymName(e.target.value)}
                placeholder="enter Gym Name"
              ></Form.Control>{" "}
              {gymNamePresent ? (
                <FieldWarning text="Gym name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="3">
              Owner Name :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                placeholder="enter owner Name"
              ></Form.Control>
              {ownerNamePresent ? (
                <FieldWarning text="Owner name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="3">
              Subscription Type<label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionTypes.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="contactNumber">
            <Form.Label column="true" sm="3">
              Contact Number <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={contactNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setConatctNumber(value);
                }}
                placeholder="enter primary contact number"
              />
              {contactNumberPresent ? (
                <FieldWarning text="Please provide 10 digit mobile number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="3">
              Email :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter gym or owner's email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="alternateNumber">
            <Form.Label column="true" sm="3">
              Alternate Contact :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="String"
                value={alternateNumber}
                onChange={(e) => {
                  setAlternateNumber(e.target.value);
                }}
                placeholder="enter secondary Contact Number"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="3">
              Address :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Gym Address"
              ></Form.Control>
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="pincode">
            <Form.Label column="true" sm="3">
              Pincode :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={pincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setPincode(value);
                }}
                placeholder="enter gym pincode"
              />
              {pincodePresent ? (
                <FieldWarning text="Please provide valid 6 digit Pincode" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Row column="true" sm="6">
          <Col sm="4"></Col>
          <Col sm="5">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              type="submit"
              disabled={isLoading}
              variant="primary"
              onClick={handleSubmit}
            >
              {" "}
              Submit
            </Button>

            <Button
              style={{ marginLeft: "20%" }}
              type="button"
              disabled={isLoading}
              variant="secondary"
              onClick={handleCancelButton}
            >
              {" "}
              Cancel
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default GymProfileEditScreen;
