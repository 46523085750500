import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Badge } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx"; // Importing xlsx library
import jsPDF from "jspdf"; // Importing jsPDF library
import "jspdf-autotable"; // Importing jsPDF Autotable plugin
import { FaSort } from "react-icons/fa";

import Loader from "../components/Loader";
import Message from "../components/Message";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { setCredentials } from "../slices/authSlice";
import { useGetPaymentsQuery } from "../slices/paymentSlice";

import {
  useCreateExpenseMutation,
  useGetExpensesQuery,
  useUpdateExpenseMutation,
} from "../slices/expenseSlice";
import { toast } from "react-toastify";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";

const AccountsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [editEnabled, setEditEnabled] = useState("");
  const [expenseAmount, setExpenseAmount] = useState();
  const [expenseType, setExpenseType] = useState();
  const [paidDate, setPaidDate] = useState(getCurrentDate());
  const [transactionId, setTransactionId] = useState("");
  const [notes, setNotes] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  const [expenseAmountPresent, setExpenseAmountPresent] = useState();
  const [expenseTypePresent, setExpenseTypePresent] = useState();
  const [paidDatePresent, setPaidDatePresent] = useState(getCurrentDate());
  const [transactionIdPresent, setTransactionIdPresent] = useState("");
  const [notesPresent, setNotesPresent] = useState("");

  const [addModalShow, setAddModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}${filterValue}&sort=-paidDate`
  );

  const [paymentsQuery, setPaymentsQuery] = useState(
    `?gymId=${userInfo.gymId._id}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}`
  );

  const [newExpenseAdded, setNewExpenseAdded] = useState("");
  const [todaysExpenseCount, setTodaysExpenseCount] = useState("");
  const [totalExpense, setTotalExpense] = useState();
  const [expensesToList, setExpensesToList] = useState([]);
  const [totalPaymentsCount, setTotalPaymentsCount] = useState();
  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState();

  const dispatch = useDispatch();

  const expenseTypes = [
    "Expense Type",
    "Salary",
    "Water",
    "Electricity",
    "Carpenter Work",
    "Plumbing Work",
    "Maintenance",
    "Equipment",
    "Others",
  ];
  const filterOptions = [
    "&expenseType=All",
    "&expenseType=Salary",
    "&expenseType=Water",
    "&expenseType=Electricity",
    "&expenseType=Carpenter Work",
    "&expenseType=Plumbing Work",
    "&expenseType=Maintenance",
    "&expenseType=Equipment",
    "&expenseType=Others",
  ];

  let {
    data: expenses,
    isLoading,
    error,
    refetch,
  } = useGetExpensesQuery({
    token: userInfo ? userInfo.token : "Bearer a",
    query: getApiQuery,
  });

  let {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: paymentsQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  setInterval(() => {
    setNewExpenseAdded(Date.now());
  }, 600000);

  const [updateExpense] = useUpdateExpenseMutation();
  const [createExpense] = useCreateExpenseMutation();

  useEffect(() => {
    if (paidDate <= getCurrentDate()) {
      setPaidDatePresent(false);
    }

    if (expenseType !== undefined && expenseType !== "Select Expense Type") {
      setExpenseTypePresent(false);
    }

    if (notes?.length < 1000) {
      setNotesPresent(false);
    }
    if (expenseAmount !== undefined && expenseAmount >= 1) {
      setExpenseAmountPresent(false);
    }

    if (transactionId?.length < 40) {
      setTransactionIdPresent(false);
    }
  }, [paidDate, expenseAmount, expenseType, transactionId, notes]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;
    console.log("paidDate");
    console.log(paidDate);
    if (paidDate === undefined || paidDate?.split("T")[0] > getCurrentDate()) {
      setPaidDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (expenseType === undefined || expenseType === "Select Expense Type") {
      setExpenseTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (!expenseAmount || expenseAmount === undefined || expenseAmount < 1) {
      setExpenseAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (transactionId?.length > 40) {
      setTransactionIdPresent(true);
      fieldValidationSuccess = false;
    }

    if (notes?.length > 1000) {
      setNotesPresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid Visitor details"
      );
    return fieldValidationSuccess;
  };

  const handleAddExpense = async (user) => {
    if (await verifyInputFeilds())
      try {
        const resData = await createExpense({
          token: userInfo.token,
          payload: {
            gymId: userInfo.gymId._id,
            expenseAmount,
            expenseType,
            paidDate,
            transactionId,
            notes,
          },
        }).unwrap();
        setNewExpenseAdded(resData);
        setPaidDate(getCurrentDate());
        setExpenseAmount("");
        setEditEnabled();
        setExpenseType("Select Expense Type");
        setTransactionId("");
        setNotes("");
        toast.success("Expense added");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const submitEdit = async () => {
    if (await verifyInputFeilds())
      try {
        await updateExpense({
          _id: editEnabled,
          token: userInfo.token,
          payload: {
            gymId: userInfo.gymId._id,
            expenseAmount,
            expenseType,
            paidDate,
            transactionId,
            notes,
          },
        }).unwrap();
        setPaidDate(getCurrentDate());
        setExpenseAmount("");
        setEditEnabled();
        setExpenseType("Select Expense Type");
        setTransactionId("");
        setNotes("");
        toast.success("Expense updated");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const hanbdleAddExpense = async (user) => {
    if (await verifyInputFeilds()) setAddModalShow(true);
  };

  const handleGetExpense = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}${filterValue}&sort=-paidDate`
      );
      setPaymentsQuery(
        `?gymId=${userInfo.gymId._id}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          accountsStartDate: startDate,
          accountsEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editEnabled, newExpenseAdded]);

  useEffect(() => {
    const todaysExpenseCount = isLoading
      ? 0
      : error
      ? 0
      : expenses
      ? expenses?.length
      : 0;

    let expenseAmount = 0;
    const aa = isLoading
      ? 0
      : error
      ? 0
      : expenses?.map((e) => {
          expenseAmount = expenseAmount + e.expenseAmount;
          return 0;
        });

    const exp = isLoading ? [] : error ? [] : expenses ? expenses : [];
    const sortedExpenses = [...exp]?.sort((a, b) => {
      if (
        a[sortConfig.key]?.toString().toLowerCase() <
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig?.direction === "asc" ? -1 : 1;
      }
      if (
        a[sortConfig.key]?.toString().toLowerCase() >
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig?.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setExpensesToList(sortedExpenses);
    setTotalExpense(expenseAmount);
    setTodaysExpenseCount(todaysExpenseCount);
    // console.log("payments");
    // console.log(payments);
    const todaysPaymentsCount = paymentsLoading
      ? 0
      : paymentsError
      ? 0
      : payments
      ? payments?.length
      : 0;
    let paymentsAmount = 0;
    const bb = paymentsLoading
      ? 0
      : paymentsError
      ? 0
      : payments?.map((e) => {
          paymentsAmount = paymentsAmount + e.paidAmount;
          return 0;
        });
    setTotalPaymentsCount(todaysPaymentsCount);
    setTotalPaymentsAmount(paymentsAmount);
  }, [
    setTodaysExpenseCount,
    expenses,
    isLoading,
    error,
    paymentsLoading,
    paymentsError,
    payments,
    sortConfig,
  ]);

  const handleSelect = (e) => {
    setExpenseType(e.target.value);
  };

  const handleStatusFilter = (val) => {
    if (val.includes("All")) setFilterValue("");
    else setFilterValue(val);
  };

  const hanbdleCancelEdit = () => {
    setEditEnabled(false);

    setPaidDate(getCurrentDate());
    setExpenseAmount("");
    setExpenseType("Select Expense Type");
    setTransactionId("");
    setNotes("");
  };

  useEffect(() => {
    if (error?.data?.error) {
      setTodaysExpenseCount(0);
      setTotalExpense(0);
    }
  }, [error]);

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Expenses available to Download for the selected Date range"
      );
    } else {
      const formattedData = expenses?.map((exp) => ({
        "Expense Type": exp.expenseType,
        "Expense Amount": exp.expenseAmount,
        "Paid Date": exp.paidDate
          ? exp.paidDate.split("T")[0]
          : exp.lastPaidDate.split("T")[0],
        "Transaction ID": exp.transactionId,
        Notes: exp.notes,
        "Added By": exp.associateId,
        "Updated By": exp.updatedBy,
      }));

      const totalPaidAmount = expenses?.reduce(
        (total, user) => total + Number(user.expenseAmount),
        0
      );

      formattedData.push({
        "Expense Type": "Total",
        "Expense Amount": totalPaidAmount,
        "Paid Date": "",
        "Transaction ID": "",
        Notes: "",
        "Added By": "",
        "Updated By": "",
      });

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Expenses");
      XLSX.writeFile(
        workBook,
        "Expenses_from " + startDate + " to " + endDate + ".xlsx"
      );
    }
  };

  // Function to export data to PDF
  const exportToPDF = () => {
    if (error) {
      toast.error(
        "No Expenses available to Download for the selected Date range"
      );
    } else {
      const doc = new jsPDF();
      doc.text("Expenses from " + startDate + " to " + endDate, 20, 10);
      doc.autoTable({
        startY: 20,
        head: [
          [
            "Expense Type",
            "Expense Amount",
            "Paid Date",
            "Transaction ID",
            "Notes",
            "Added By",
            "Updated By",
          ],
        ],
        body: [
          ...expenses?.map((exp) => [
            exp.expenseType,
            exp.expenseAmount,
            exp.paidDate
              ? exp.paidDate.split("T")[0]
              : exp.lastPaidDate.split("T")[0],
            exp.transactionId,
            exp.notes,
            exp.associateId,
            exp.updatedBy,
          ]),
          [
            { content: "Total", styles: { fontStyle: "bold" } }, // "Total" in bold
            {
              content: expenses?.reduce(
                (total, user) => total + Number(user.expenseAmount),
                0
              ),
              styles: { fontStyle: "bold" }, // Total amount in bold
            },
            "",
            "",
            "",
          ],
        ],
      });
      doc.save("Expenses_from " + startDate + " to " + endDate + ".pdf");
    }
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  return (
    <>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <label>
            <strong>Accounts & Finance : &nbsp;</strong>
          </label>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={(e) => setShowAdd(!showAdd)}
            variant="primary"
          >
            {showAdd ? "Show Expenses" : "Add Expense"}
          </Button>
          <br />
          <br />
          <VerticallyCenteredModal
            title="Edit this Expense?"
            show={modalShow}
            size="sm"
            onHide={() => setModalShow(false)}
            onSubmit={submitEdit}
            setEdit={setEditEnabled}
          />
          <VerticallyCenteredModal
            title="Add this Expense ?"
            show={addModalShow}
            size="sm"
            onHide={() => setAddModalShow(false)}
            onSubmit={handleAddExpense}
          />
          {!showAdd ? (
            <>
              <Row
                className="mb-0"
                style={{
                  margin: "auto",
                  width: "95%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col xs={4} md="auto" sm={4}>
                  <Form.Group controlId="visitorMobile">
                    <Form.Control
                      type="date"
                      placeholder="2023-11-23"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={4} md="auto" sm={4}>
                  <Form.Group controlId="visitorMobile">
                    <Form.Control
                      type="date"
                      placeholder="2023-11-27"
                      value={endDate?.split("T")[0]}
                      onChange={(e) => setEndDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={4} md="auto" sm={4}>
                  <Form.Group controlId="filterBy">
                    <Form.Select
                      id="filterBy"
                      value={filterValue}
                      onChange={(e) => handleStatusFilter(e.target.value)}
                    >
                      {filterOptions?.map((e) => (
                        <option key={e} value={e}>
                          {e?.split("=")[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col
                  xs={12}
                  md="auto"
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Form.Group as={Row} className="my-1" controlId="gymType">
                    <Button
                      className={`${userInfo.themeColor}ThemeHeaderTop`}
                      id="filterBy"
                      value={filterValue}
                      onClick={handleGetExpense}
                      style={{ backgroundColor: "#124137" }}
                    >
                      Get Expenses
                    </Button>
                  </Form.Group>
                </Col>
                <Col
                  xs={6}
                  md="auto"
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Button onClick={exportToExcel} variant="success">
                    Download Excel
                  </Button>
                </Col>
                <Col
                  xs={6}
                  md="auto"
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Button onClick={exportToPDF} variant="danger">
                    Download PDF
                  </Button>
                </Col>
              </Row>
              <Row
                className="mb-2"
                style={{
                  margin: "auto",
                  width: "95%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ backgroundColor: "#27aa67", pointerEvents: "none" }}
                  className="marginPointOne sixteenWidth numbersButtonDashboard"
                >
                  Income:{" "}
                  <strong>
                    {totalPaymentsAmount}
                    {/* <Badge>{totalPaymentsCount}</Badge> */}
                  </strong>
                </Button>
                <Button
                  style={{ pointerEvents: "none" }}
                  className="marginPointOne sixteenWidth buttonOrchid numbersButtonDashboard"
                >
                  Expense:{" "}
                  <strong>
                    {totalExpense}
                    {/* <Badge>{todaysExpenseCount}</Badge> */}
                  </strong>
                </Button>
                <Button
                  style={{ pointerEvents: "none" }}
                  className="marginPointOne sixteenWidth buttonOrange numbersButtonDashboard"
                >
                  Profit:{" "}
                  <strong>
                    {totalPaymentsAmount - totalExpense}
                    {/* <Badge>1</Badge> */}
                  </strong>
                </Button>
              </Row>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message>{error?.data?.error}</Message>
              ) : (
                <div style={{ height: "100vh" }}>
                  <div className="tableContainer">
                    <Table
                      striped
                      bordered
                      hover
                      responsive="sm"
                      className="table-custom"
                    >
                      <thead className={`${userInfo.themeColor}ThemeThead`}>
                        <tr>
                          <th
                            onClick={() => handleSort("expenseType")}
                            style={{ cursor: "pointer" }}
                          >
                            Expense Type
                            <FaSort />
                          </th>
                          <th>Amount</th>
                          <th
                            onClick={() => handleSort("paidDate")}
                            style={{ cursor: "pointer" }}
                          >
                            Date
                            <FaSort />
                          </th>
                          <th>Txn Id</th>
                          <th>Added By</th>
                          <th>Updated By</th>
                          <th>Notes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                        {expensesToList ? (
                          expensesToList?.map((exp) => (
                            <>
                              {editEnabled === exp._id ? (
                                <tr>
                                  <td>
                                    <Form.Select
                                      id="searchBy"
                                      value={expenseType || exp.expenseType}
                                      onChange={(e) => handleSelect(e)}
                                      style={{ height: "100%" }}
                                    >
                                      {expenseTypes?.map((o) => (
                                        <option key={o} value={o}>
                                          {o}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {expenseTypePresent ? (
                                      <FieldWarning text="Please select valid status" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      value={expenseAmount}
                                      type="text"
                                      onChange={(e) =>
                                        setExpenseAmount(e.target.value)
                                      }
                                    ></Form.Control>
                                    {expenseAmountPresent ? (
                                      <FieldWarning text="Please Provide expense amount" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      value={paidDate?.split("T")[0]}
                                      type="date"
                                      onChange={(e) =>
                                        setPaidDate(e.target.value)
                                      }
                                    ></Form.Control>
                                    {paidDatePresent ? (
                                      <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      pattern="[0-9]*" // Allow only digits
                                      value={transactionId}
                                      onChange={(e) =>
                                        setTransactionId(e.target.value)
                                      }
                                    />
                                    {transactionIdPresent ? (
                                      <FieldWarning text="Transaction Id should not exceed 40 chars" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td></td> <td></td>
                                  <td>
                                    <Form.Control
                                      value={notes}
                                      type="text"
                                      onChange={(e) => setNotes(e.target.value)}
                                    ></Form.Control>
                                    {notesPresent ? (
                                      <FieldWarning text="notes should not have more then 1000 chars" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {editEnabled ? (
                                      <>
                                        <button
                                          onClick={hanbdleSubmit}
                                          style={{
                                            borderRadius: "10%",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <CheckLg />
                                        </button>
                                        <button
                                          onClick={hanbdleCancelEdit}
                                          style={{
                                            borderRadius: "10%",
                                            margin: "8%",
                                          }}
                                        >
                                          <XLg />
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>{exp.expenseType}</td>
                                  <td>{exp.expenseAmount}</td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {exp.paidDate
                                      ? exp.paidDate?.split("T")[0]
                                      : ""}
                                  </td>
                                  <td>{exp.transactionId}</td>
                                  <td>{exp.associateId}</td>
                                  <td>{exp.updatedBy}</td>
                                  <td>{exp.notes}</td>

                                  <td>
                                    <button
                                      style={{ borderRadius: "20%" }}
                                      onClick={() => {
                                        setExpenseAmount(exp.expenseAmount);
                                        setEditEnabled(exp._id);
                                        setNotes(exp.notes);
                                        setTransactionId(exp.transactionId);
                                        setPaidDate(exp.paidDate);
                                        setExpenseType(exp.expenseType);
                                      }}
                                    >
                                      <Pencil />
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        ) : (
                          <Message>No visitors Available</Message>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Row
              className="mb-0"
              style={{
                margin: "auto",
                width: "95%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  Select Expense <label style={{ color: "red" }}>*</label> :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Select
                    id="searchBy"
                    value={expenseType}
                    onChange={(e) => handleSelect(e)}
                    style={{ height: "100%" }}
                  >
                    {expenseTypes?.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </Form.Select>
                  {expenseTypePresent ? (
                    <FieldWarning text="Please select valid type" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  Expense Amount<label style={{ color: "red" }}>*</label> :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Group controlId="visitorMobile">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*" // Allow only digits
                      value={expenseAmount}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setExpenseAmount(value);
                      }}
                      placeholder="Expense Amount *"
                    />
                  </Form.Group>
                  {expenseAmountPresent ? (
                    <FieldWarning text="Amount should be atleast 1 Rs" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  Expense Date :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Group controlId="paidDate">
                    <Form.Control
                      type="date"
                      value={paidDate}
                      onChange={(e) => setPaidDate(e.target.value)}
                      placeholder="paid Date"
                    />
                  </Form.Group>
                  {paidDatePresent ? (
                    <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  Transaction Id :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Group controlId="visitorName">
                    <Form.Control
                      type="text"
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      placeholder="transaction Id"
                    />
                  </Form.Group>
                  {transactionIdPresent ? (
                    <FieldWarning text="Transaction id shoould not exceed 40 chars" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  Notes to remember :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Group controlId="notes">
                    <Form.Control
                      type="text"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Notes"
                    />
                  </Form.Group>
                  {notesPresent ? (
                    <FieldWarning text="notes should not have more then 1000 chars" />
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Col
                  xs={6}
                  md={8}
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Button
                    className={`${userInfo.themeColor}ThemeHeaderTop`}
                    style={{ backgroundColor: "#124137" }}
                    onClick={hanbdleAddExpense}
                    variant="primary"
                  >
                    Add Expense
                  </Button>
                </Col>
              </Form.Group>
            </Row>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          {" "}
          <label style={{ color: "red" }}>
            This feature is available only for Premium Plus.
          </label>
          <br></br> <br></br>
          <h5>
            Please upgrade to Premium Plus to get Accounts & Finance feature.
          </h5>{" "}
          <h6> You can track all the incomes and expenses of your gym here.</h6>
        </div>
      )}
    </>
  );
};

export default AccountsScreen;
