import { Button, Form, Row, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import {
  useGetAttendanceQuery,
  useUpdateAttendanceMutation,
} from "../slices/attendanceSlice";
import welcomeMessage from "../assets/audios/messageTone.mp3";
import errorTone from "../assets/audios/error_tone.mp3";
import { setCredentials } from "../slices/authSlice";
import ProfileImage from "../components/ProfileImage";
import { getCurrentDate } from "../utils/getCurrentDate";
import { useGetVisitorsQuery } from "../slices/visitorsSlice";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import { useGetUserQuery, useGetUsersQuery } from "../slices/usersSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const TrendsScreen = () => {
  const [userId, setUserId] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const [attendanceToday, setAttendanceToday] = useState(0);
  const [visitorsToday, setVisitorsToday] = useState(0);
  const [subscriptionsToday, setSubscriptionsToday] = useState(0);
  const [renewalsToday, setRenewalsToday] = useState(0);
  const [paymentsToday, setPaymentsToday] = useState(0);
  const [trendsVisible, setTrendsVisible] = useState(
    userInfo.trendsVisible ? userInfo.userInfo : true
  );
  const [refreshTrend, setRefreshTrend] = useState(false);
  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();

  const [isToggled, setIsToggled] = useState(userInfo.audioOn);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setIsToggled(!isToggled);
    dispatch(setCredentials({ ...userInfo, audioOn: !isToggled }));
  };

  // const [toastMessage, setToastMessage] = useState(false);

  // const handleToastMessage = () => {
  //   setToastMessage(!toastMessage);
  // };
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
  let startDate =
    new Date(`${getCurrentDate()}T00:00:00.567Z`).getTime() - offset;

  let endDate =
    new Date(`${getCurrentDate()}T23:59:59.567Z`).getTime() - offset;
  const {
    data: attendances,
    isLoading: isAttendanceLoading,
    error,
    refetch,
  } = useGetAttendanceQuery({
    query: `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate}&fields=userId,timeIn,timeOut`,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  let {
    data: visitors,
    isLoading: isVisitorsLoading,
    error: errorInVisitors,
    refetch: refetchVisitors,
  } = useGetVisitorsQuery({
    token: userInfo.token,
    query: `createdAt[gte]=${startDate}&fields=_id`,
  });

  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}&fields=paidAmount`,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: renewals,
    isLoading: renewalsLoading,
    error: renewalError,
    refetch: renewalsRefetch,
  } = useGetRenewalsQuery({
    query: `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}&fields=_id`,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
    refetch: usersRefetch,
  } = useGetUsersQuery({
    query: `?gymId=${userInfo.gymId._id}&joinedDate[gte]=${startDate}&fields=_id`,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  useEffect(() => {
    refetch();
    const att = isAttendanceLoading ? [] : attendances ? attendances : [];
    setAttendanceToday(att?.length);

    refetchVisitors();
    const visit = isVisitorsLoading ? [] : visitors ? visitors : [];
    setVisitorsToday(visit?.length);

    paymentsRefetch();
    const pays = paymentsLoading ? [] : payments ? payments : [];
    const totalPaidAmount = pays.reduce(
      (acc, member) => acc + member.paidAmount,
      0
    );
    setPaymentsToday(totalPaidAmount);

    renewalsRefetch();
    let renews = renewalsLoading ? [] : renewals ? renewals : [];
    renews = renews.filter(
      (rn) =>
        rn.compositeId.joinedDate?.toString().split("T")[0] !==
        getCurrentDate().toString()
    );
    setRenewalsToday(renews?.length);

    usersRefetch();
    const usrs = usersLoading ? [] : users ? users : [];
    setSubscriptionsToday(usrs?.length);

    const currentUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => {
          return att.timeIn >= Date.now() - 3600000 && !att.timeOut;
        })
      : [];
    setCurrentUsersAtGym(currentUsers);
  }, [
    attendances,
    refetch,
    visitors,
    refetchVisitors,
    payments,
    paymentsRefetch,
    renewals,
    renewalsRefetch,
    users,
    usersRefetch,
    refreshTrend,
  ]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userInfo) toast.error("Please login to use the application");
    if (
      userId?.toString().length < 1 ||
      userId?.toString().length > 999999 ||
      !userId
    )
      toast.error("Please enter valid Member Id");
    else {
      try {
        const res = await updateAttendance({
          payLoad: {
            userId: userId.toString().toLowerCase(),
          },
          token: userInfo.token,
        }).unwrap();
        // dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        // navigate("/");
        if (res.subsAlreadyExpired) {
          toast.error(
            () => (
              <div
                style={{
                  width: "550px",
                  padding: "20px",
                  fontSize: "1.2em",
                  lineHeight: "1.5",
                  backgroundColor: "red",
                }}
              >
                <audio
                  src={errorTone}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <ProfileImage
                  style={{ width: "500px", height: "auto", margin: "10px 0" }}
                  imageId={userInfo?.gymId._id + "" + res?.userId}
                />{" "}
                <br></br>
                <label style={{ color: "white" }}>
                  Subscription EXPIRED on : {res?.expiresOn?.split("T")[0]}
                </label>{" "}
                <br></br>
              </div>
            ),
            {
              style: { width: "600px" }, // Increase the width of the toast itself
            }
          );
          setRefreshTrend(!refreshTrend);
        } else {
          toast.success(
            () => (
              <div
                style={{
                  width: "550px",
                  padding: "20px",
                  fontSize: "1.2em",
                  lineHeight: "1.5",
                }}
              >
                <audio
                  src={welcomeMessage}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <ProfileImage
                  style={{ width: "500px", height: "auto", margin: "10px 0" }}
                  imageId={userInfo.gymId._id + "" + res?.userId}
                />
                <br />
                <strong>
                  {res?.message} {res?.userName}
                </strong>
                <br />
                Valid Till: {res?.expiresOn?.split("T")[0]}
                <br />
                Expires in:{" "}
                {Math.ceil(
                  (new Date(res?.expiresOn) - new Date()) /
                    (1000 * 60 * 60 * 24)
                )}{" "}
                days
              </div>
            ),
            {
              style: { width: "600px" }, // Increase the width of the toast itself
            }
          );

          setRefreshTrend(!refreshTrend);
        }
        setUserId("");
      } catch (err) {
        err.status === "PARSING_ERROR" || err.status === 400
          ? toast.error(() => (
              <div>
                <audio
                  src={errorTone}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <br />
                Please enter valid Member ID
              </div>
            ))
          : toast.error(err.error);
      }
    }
  };

  const showHideTrends = async () => {
    setTrendsVisible(!trendsVisible);
    dispatch(
      setCredentials({
        ...userInfo,
        trendsVisible,
      })
    );
  };

  return (
    <>
      {userInfo?.role === "owner" || userInfo?.role === "Super Admin" ? (
        <>
          {trendsVisible ? (
            <>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                  display: "flex", // Ensures flex layout
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                <label
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    borderRadius: "5px",
                  }}
                >
                  {
                    preferredLanguage[selectedLanguage]?.attendancePage
                      .todaysTrend
                  }
                </label>{" "}
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeHeaderLeft`}
                  style={{ backgroundColor: "#185648" }}
                >
                  {preferredLanguage[selectedLanguage]?.attendancePage.atGym}
                  <Badge style={{ marginLeft: "8px" }}>
                    {currentUsersAtGym.length}
                  </Badge>
                </label>
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeTbody`}
                  style={{ backgroundColor: "#278772", color: "black" }}
                >
                  {
                    preferredLanguage[selectedLanguage]?.attendancePage
                      .totalAttendance
                  }
                  <Badge style={{ marginLeft: "8px" }}>{attendanceToday}</Badge>
                </label>
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeHeaderLeft`}
                  style={{ backgroundColor: "#185648" }}
                >
                  {preferredLanguage[selectedLanguage]?.attendancePage.visitors}
                  <Badge style={{ marginLeft: "8px" }}>{visitorsToday}</Badge>
                </label>
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeTbody`}
                  style={{ backgroundColor: "#278772", color: "black" }}
                >
                  {preferredLanguage[selectedLanguage]?.attendancePage.subs}
                  <Badge style={{ marginLeft: "8px" }}>
                    {subscriptionsToday}
                  </Badge>
                </label>
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "0.5%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeHeaderLeft`}
                  style={{ backgroundColor: "#185648" }}
                >
                  {preferredLanguage[selectedLanguage]?.attendancePage.ren}
                  <Badge style={{ marginLeft: "8px" }}>{renewalsToday}</Badge>
                </label>
              </Row>
              <Row
                className="mb-1"
                style={{
                  width: "95%",
                  margin: "auto",
                  paddingBottom: "1%",
                  justifyContent: "center",
                }}
              >
                <label
                  className={`trendsLabel ${userInfo.themeColor}ThemeTbody`}
                  style={{ backgroundColor: "#278772", color: "black" }}
                >
                  {preferredLanguage[selectedLanguage]?.attendancePage.payments}{" "}
                  <Badge>{paymentsToday}</Badge>
                </label>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row
            className="mb-1"
            style={{
              width: "95%",
              margin: "auto",
              paddingBottom: "0.5%",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "orange",
                width: "120px",
                margin: "3px",
                borderRadius: "5px",
                color: "black",
              }}
              onClick={showHideTrends}
            >
              {trendsVisible
                ? preferredLanguage[selectedLanguage]?.attendancePage.hideButton
                : preferredLanguage[selectedLanguage]?.attendancePage
                    .showButton}
            </Button>{" "}
            {/* <button
          style={{
            backgroundColor: "#27aa67",
            width: "170px",
            margin: "3px",
            borderRadius: "5px",
          }}
          onClick={handleRefreshTrend}
        >
          Refresh
        </button> */}
          </Row>
        </>
      ) : (
        <></>
      )}
      <br />
    </>
  );
};

export default TrendsScreen;
