import { apiSlice } from "./apiSlice";
import { WS_MESSAGE_URL } from "../constants";

const wsMessageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendWsMessage: builder.mutation({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}${data?.params ? "/" + data.params : ""}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),

    getWsMessage: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}${data?.query ? data.query : ""}`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    createWhatsAppAds: builder.mutation({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/createWhatsAppAds`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    getWhatsAppAds: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/getWhatsAppAds?${
          data?.query ? data.query : ""
        }`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    getMyWhatsAppAds: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/getMyWhatsAppAds?${
          data?.query ? data.query : ""
        }`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    getWhatsAppBalance: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/getWhatsAppBalance`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
  }),
});

export const {
  useSendWsMessageMutation,
  useGetWsMessageQuery,
  useCreateWhatsAppAdsMutation,
  useGetWhatsAppAdsQuery,
  useGetWhatsAppBalanceQuery,
  useGetMyWhatsAppAdsQuery,
} = wsMessageSlice;
