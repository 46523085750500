import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import {
  useCreateWhatsAppAdsMutation,
  useGetWhatsAppBalanceQuery,
} from "../slices/wsMessageSlice";
import { toast } from "react-toastify";
import VerticallyCenteredModal from "../components/SubmitModal";
import Loader from "../components/Loader";

const WhatsAppAdsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [promotionName, setPromotionName] = useState("");
  const [promotionMessage, setPromotionMessage] = useState("");
  const [recipientMobiles, setRecipientMobiles] = useState("");
  const [recipientMobilesArray, setRecipientMobilesArray] = useState([""]);
  const [sheduledTime, setSheduledTime] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [whatsAppBalance, setWhatsAppBalance] = useState();
  const [rechargeEnabled, setRechargeEnabled] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [editEnabled, setEditEnabled] = useState("");

  const [promotionNamePresent, setPromotionNamePresent] = useState(false);
  const [promotionMessagePresent, setPromotionMessagePresent] = useState(false);
  const [recipientMobilesIsNumber, setRecipientMobilesIsNumber] =
    useState(false);
  const [recipientMobilesArrayPresent, setRecipientMobilesArrayPresent] =
    useState(false);

  const [recipientCountWithinLimit, setRecipientCountWithinLimit] =
    useState(false);

  const [createWhatsAppAds] = useCreateWhatsAppAdsMutation();

  let {
    data: whatsAppRes,
    isLoading,
    error,
    refetch,
  } = useGetWhatsAppBalanceQuery({
    token: userInfo.token,
  });

  useEffect(() => {
    if (promotionName?.length > 1 && promotionName?.length < 150) {
      setPromotionNamePresent(false);
    }

    if (promotionMessage?.length > 1 && promotionMessage?.length < 1999) {
      setPromotionMessagePresent(false);
    }

    if (recipientMobiles?.length > 1) {
      setRecipientMobilesArrayPresent(false);
      setRecipientMobilesIsNumber(false);
      setRecipientCountWithinLimit(false);
    }
  }, [promotionName, promotionMessage, recipientMobiles]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (promotionName?.length < 1 || promotionName?.length > 150) {
      setPromotionNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (promotionMessage?.length < 1 || promotionMessage?.length > 1999) {
      setPromotionMessagePresent(true);
      fieldValidationSuccess = false;
    }
    const mobileArray = recipientMobiles?.trim().split("\n");
    setRecipientMobilesArray(mobileArray);

    // console.log("recipientMobilesArray");
    // console.log(mobileArray);
    if (
      mobileArray?.length < 1 ||
      mobileArray?.length > 1000 ||
      (mobileArray?.length === 1 &&
        (mobileArray[0] === "" || mobileArray[0].length !== 10))
    ) {
      setRecipientMobilesArrayPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileArray?.length > whatsAppBalance) {
      setRecipientCountWithinLimit(true);
      fieldValidationSuccess = false;
    }

    for (let i = 0; i < mobileArray.length; i++) {
      if (
        isNaN(Number(mobileArray[i])) ||
        (!isNaN(Number(mobileArray[i])) && mobileArray[i].length !== 10)
      ) {
        setRecipientMobilesIsNumber(true);
        fieldValidationSuccess = false;
        break;
      }
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const hanbdleSubmit = async () => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const handleSendMessage = async () => {
    try {
      const res = await createWhatsAppAds({
        payload: {
          promotionName,
          promotionMessage,
          recipientMobiles: recipientMobilesArray,
          sentTime: new Date().getTime().toString(),
        },
        token: userInfo.token,
      }).unwrap();
      toast.success(res?.Message);
      setPromotionName("");
      setPromotionMessage("");
      setRecipientMobiles("");
      setRecipientMobilesArray([""]);
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  useEffect(() => {
    refetch();
    const msgBal = error
      ? 0
      : isLoading
      ? 0
      : whatsAppRes?.wsMessageBalance || 0;
    setWhatsAppBalance(msgBal);
  }, [, whatsAppRes, refetch]);

  const navigate = useNavigate();
  const handleHistoryButton = () => {
    navigate("/wsMessageAdsList");
  };

  return (
    <>
      <h6>
        Please provide the details to send WhatsApp Ads :&nbsp;&nbsp;
        <Row
          className="my-3"
          style={{
            margin: "auto",
            width: "100%%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="marginPointOne sixteenWidth buttonGreen numbersButtonDashboard"
            style={{ pointerEvents: "none" }}
          >
            Balance : {whatsAppBalance}
          </Button>
          <Button
            className="marginPointOne sixteenWidth buttonOrchid numbersButtonDashboard"
            onClick={(e) => setRechargeEnabled(true)}
          >
            Recharge
          </Button>
          <Button
            onClick={handleHistoryButton}
            className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
          >
            See History
          </Button>
        </Row>
      </h6>
      <VerticallyCenteredModal
        title="Are you sure want to submit WhatsApp Ads ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={handleSendMessage}
        setEdit={setEditEnabled}
      />
      {rechargeEnabled ? (
        <Row
          className={`${userInfo.themeColor}ThemeHeaderTop`}
          style={{
            justifyContent: "center",
            padding: "2%",
            margin: "3%",
            // backgroundColor: "white",
            color: "black",
            borderRadius: "20px",
          }}
        >
          <Col
            md={6}
            style={{
              margin: "auto",
              textAlign: "center",
            }}
          >
            <h5>Pay Rs.2000 for 4000 WhatsApp messages</h5>
            <h5>Pay Rs.3000 for 6000 WhatsApp messages</h5>
            <h5>Pay Rs.5000 for 10000 WhatsApp messages</h5>
            <Button className=" buttonReddish">
              {" "}
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
                href="https://rzp.io/l/6FPnpZwy"
                target="_blank"
              >
                Click to Pay
              </a>
            </Button>
            <Button
              onClick={(e) => setRechargeEnabled(false)}
              style={{ backgroundColor: "grey", marginLeft: "15px" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          {" "}
          <Row className="my-2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Form.Label>Campaign Name :&nbsp;&nbsp; </Form.Label>{" "}
              <Form.Control
                placeholder="type Campaign name"
                style={{ width: "50%" }}
                value={promotionName}
                onChange={(e) => setPromotionName(e.target.value)}
              ></Form.Control>
            </div>
            {promotionNamePresent && (
              <label style={{ color: "red" }}>
                Please provide Campaign Name
              </label>
            )}
          </Row>
          <Row className="my-2">
            <Col md={4}>
              <Card>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Mobile Number Here Like 
919898000001
919099000002
919898000003
919099000004
919099000005"
                  value={recipientMobiles}
                  onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value
                      .replace(/,/g, "\n")
                      .replace(/[^\d\n]/g, ""); // Keep numbers and newlines only
                    setRecipientMobiles(filteredValue);
                  }}
                  style={{
                    height: "300px",
                    width: "100%",
                    overflowY: "scroll", // Enable vertical scrolling
                    resize: "none", // Prevent resizing (optional)
                  }}
                />
              </Card>
              {recipientMobilesArrayPresent && (
                <label style={{ color: "red" }}>
                  Please provide valid Mobile Numbers
                </label>
              )}
              {recipientMobilesIsNumber && (
                <label style={{ color: "red" }}>
                  Some of Mobile Numbers are not 10 digit, Please check the
                  numbers
                </label>
              )}
              {recipientCountWithinLimit && (
                <label style={{ color: "red" }}>
                  You do not have enough message balance. Please add message
                  balance or reduce mobile numbers count.
                </label>
              )}
            </Col>
            <Col md={8}>
              <Card>
                {" "}
                <Form.Control
                  as="textarea"
                  placeholder="Type message content here"
                  value={promotionMessage}
                  onChange={(e) => setPromotionMessage(e.target.value)}
                  style={{
                    height: "300px",
                    width: "100%",
                    overflowY: "scroll", // Enable vertical scrolling
                    resize: "none", // Prevent resizing (optional)
                  }}
                />
              </Card>
              {promotionMessagePresent && (
                <label style={{ color: "red" }}>
                  Please provide Message content
                </label>
              )}
            </Col>
          </Row>
          {/* <Row className="my-3">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Control
            type="file"
            style={{ margin: "5px", width: "300px" }}
            // onChange={handleFileChange} // Function to handle file changes
          />

          <Form.Control
            type="file"
            style={{ margin: "5px", width: "300px" }}
            // onChange={handleFileChange} // Function to handle file changes
          />

          <Form.Control
            type="file"
            style={{ margin: "5px", width: "300px" }}
            // onChange={handleFileChange} // Function to handle file changes
          />
        </div>
      </Row>{" "} */}
          <br />
          <Row className="mb-2">
            <div
              style={{
                margin: "auto",
                width: "95%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className={`marginPointOne sixteenWidth ${userInfo.themeColor}ThemeHeaderTop numbersButtonDashboard`}
                onClick={hanbdleSubmit}
              >
                Send Message
              </Button>
            </div>
          </Row>
          <br />
          <Row className="my-2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "white" }}>
                <strong>Note :</strong> All Campaign will be process between
                10:00 AM to 6:00 PM working days.If you send any spam or abusing
                messages or personal message then your credit will suspended and
                legal action will be taken .
              </p>
            </div>
          </Row>
        </>
      )}
    </>
  );
};

export default WhatsAppAdsScreen;
