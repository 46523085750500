import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useUpdateAttendanceMutation } from "../slices/attendanceSlice";
import { useNavigate, useParams } from "react-router-dom";

import welcomeMessage from "../assets/audios/messageTone.mp3";
import { useUpdateAssociateAttendanceMutation } from "../slices/associatesSlice";

const AssociateAttendanceFromMobile = () => {
  const [associateId, setAssociateId] = useState();
  const { gymUid: gymIdInUrl } = useParams();
  const navigate = useNavigate();
  // const [gymUid, setEncodedUrl] = useState(gymIdInUrl);
  let encodeVal = Date.now().toString();
  const startVal = encodeVal?.substring(0, 5);
  const endVal = encodeVal?.substring(5);
  const redirectUrl = false;
  // alert(`${startVal}${endVal}`);

  useEffect(() => {
    // alert(`${startVal}${gymIdInUrl}${endVal}`);
    navigate(
      `/associateAttendanceFromMobile/${startVal}${gymIdInUrl}${endVal}`
    );
  }, [redirectUrl, navigate]);

  const [updateAttendance, { isLoading }] =
    useUpdateAssociateAttendanceMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!userInfo) toast.error("Please login to use the application");
    if (associateId < 1 || associateId > 999999 || associateId.startsWith("0"))
      toast.error("Please enter valid user Id");
    else {
      try {
        const res = await updateAttendance({
          payload: {
            associateId: associateId,
          },
          params: gymIdInUrl,
        }).unwrap();
        // dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        // navigate("/");

        toast.success(() => (
          <div>
            <audio src={welcomeMessage} type="audio/mp3" autoPlay></audio>
            {res.message}
            <br />
            <strong>
              Thank You {res?.data?.compositeId?.toString().substring(4)}
            </strong>
          </div>
        ));

        setAssociateId("");
      } catch (err) {
        err?.status?.status === "PARSING_ERROR"
          ? toast.error("Please enter valid user Id")
          : toast.error(err.data.error);
      }
    }
  };

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        height: "50vh",
        width: "56vh",
      }}
    >
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form.Group className="my-1" controlId="userId">
              <Form.Label id="userId">Enter Employee Id</Form.Label>
              <Form.Control
                style={{ height: "50px" }}
                type="number"
                placeholder="enter Id"
                autoComplete="off"
                autoFocus
                value={associateId}
                onChange={(e) => setAssociateId(e.target.value)}
              />
            </Form.Group>
            <br />
            <Button
              style={{ height: "40px" }}
              className="cyanThemeHeaderTop"
              type="submit"
              variant="primary"
            >
              Submit
            </Button>
          </div>
          {isLoading && <Loader />}
        </Form>
      </FormContainer>
    </div>
  );
};

export default AssociateAttendanceFromMobile;
